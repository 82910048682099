import React from "react";
import Header from "../components/Header";
import WpTg from "../components/WpTg";
import { motion } from "framer-motion";
import Form from "../components/Form";
import { FlexBox, FlexCol } from "../components/FlexBox";

const Contact = ({ variants, windowDimenion }) => {
  return (
    <>
      <Header windowDimenion={windowDimenion} devLanding />

      <FlexBox
        className="section heroSec"
        id="inicio"
        allign="center"
        justify="space"
        direction="row"
      >
        <FlexBox
          className="container-lg"
          allign="left"
          direction="column"
          wrap="wrap"
        >
          <FlexCol className="titleBox" direction="column" colWidth="5 1 55%">
            <motion.h5
              initial={{ x: -300, opacity: 0 }}
              animate={{
                x: 0,
                opacity: 1,
                transition: { type: "spring", duration: 0.8, delay: 0.3 },
              }}
              className="primary shadow"
            >
              Contact
            </motion.h5>
            <motion.h1
              initial={{ x: -300, opacity: 0 }}
              animate={{
                x: 0,
                opacity: 1,
                transition: { type: "spring", duration: 0.8, delay: 0.8 },
              }}
            >
              Contact{" "}
              <motion.span
                initial={{ x: -300, opacity: 0 }}
                animate={{
                  x: 0,
                  opacity: 1,
                  transition: { type: "spring", duration: 0.8, delay: 1.5 },
                }}
                className="titleResalt"
              >
                with us.
              </motion.span>
            </motion.h1>
          </FlexCol>
        </FlexBox>
      </FlexBox>
      {/* <Clientes variants={variants}/> */}
      <Form variants={variants} devLanding />

      <WpTg />
    </>
  );
};

export default Contact;
