import React from "react";
import { FlexBox, FlexCol } from "./FlexBox";
import { motion } from "framer-motion";
import "../styles/Escala.css";
import { Link } from "react-router-dom";

const PortfolioSec = ({ variants }) => {
  return (
    <FlexBox
      id="portfolio"
      className="section bckColor"
      allign="center"
      direction="col"
    >
      <FlexBox
        className="container-lg"
        allign="center"
        justify="center"
        direction="row"
        wrap="wrap"
      >
        <FlexCol direction="column" allign="flex-start" colWidth="70%">
          <motion.h5
            viewport={{ once: true }}
            initial="initial"
            whileInView="fadeinup"
            className="secondary shadow"
          >
            Portfolio
          </motion.h5>
          <motion.h2
            className="white"
            viewport={{ once: true }}
            variants={variants}
            initial="initial"
            whileInView="fadeinup"
          >
            From concept to Launch
          </motion.h2>
          <motion.p
            className="white"
            viewport={{ once: true }}
            variants={variants}
            initial="initial"
            whileInView="fadeinup"
          >
            Browse our portfolio section and see how we've helped businesses
            create unique, high-performance websites with exceptional UX/UI
            design. Boton de Our Portfolio.
          </motion.p>
        </FlexCol>
        <FlexCol
          direction="column"
          allign="center"
          justify="center"
          colWidth="30%"
          className="buttonPortfolio"
        >
          <motion.div
            viewport={{ once: true }}
            variants={variants}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            initial="initial"
            whileInView="fadeinup"
            style={{ display: "flex" }}
          >
            <Link className="button shadow secondary" to={"/portfolio"}>
              View portfolio
            </Link>
          </motion.div>
        </FlexCol>
      </FlexBox>
    </FlexBox>
  );
};

export default PortfolioSec;
