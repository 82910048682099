import React from "react";
import { motion } from "framer-motion";
import "../styles/Tecnologias.css";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/autoplay";

/* Tecnologias */
import css from "../assets/tech/css.svg";
import html from "../assets/tech/html.svg";
import javascript from "../assets/tech/javascript.svg";
import mysql from "../assets/tech/mysql.svg";
import nodejs from "../assets/tech/nodejs.svg";
import react from "../assets/tech/react.svg";
import wordpress from "../assets/tech/wordpress.svg";
import figma from "../assets/tech/figma.svg";

/* App */
import aws from "../assets/tech/appdev/aws.png";
import c from "../assets/tech/appdev/c.png";
import django from "../assets/tech/appdev/django.png";
import firebase from "../assets/tech/appdev/firebase.png";
import flask from "../assets/tech/appdev/flask.svg";
import laravel from "../assets/tech/appdev/laravel.png";
import owasp from "../assets/tech/appdev/owasp.png";
import php from "../assets/tech/appdev/php.png";
import python from "../assets/tech/appdev/python.png";

const Tecnologias = ({ variants, app }) => {
  const technologies = [
    {
      src: css,
      alt: "CSS",
    },
    {
      src: html,
      alt: "HTML",
    },
    {
      src: javascript,
      alt: "JavaScript",
    },
    {
      src: mysql,
      alt: "MySQL",
    },
    {
      src: nodejs,
      alt: "Node JS",
    },
    {
      src: react,
      alt: "React",
    },
    {
      src: wordpress,
      alt: "Wordpress",
    },
    {
      src: figma,
      alt: "Figma",
    },
  ];
  const apptechnologies = [
    {
      src: aws,
      alt: "AWS",
    },
    {
      src: c,
      alt: "C",
    },
    {
      src: django,
      alt: "django",
    },
    {
      src: firebase,
      alt: "Firebase",
    },
    {
      src: flask,
      alt: "flask",
    },
    {
      src: laravel,
      alt: "laravel",
    },
    {
      src: owasp,
      alt: "owasp",
    },
    {
      src: php,
      alt: "php",
    },
    {
      src: python,
      alt: "python",
    },
    {
      src: react,
      alt: "React",
    },
  ];
  return (
    <>
      <Swiper
        className="tech-swiper"
        modules={[Autoplay]}
        spaceBetween={10}
        grabCursor={false}
        speed={3000}
        autoplay={{
          delay: 3,
        }}
        breakpoints={{
          320: {
            slidesPerView: 4,
          },
          768: {
            slidesPerView: 4,
          },
          1024: {
            slidesPerView: 4,
          },
        }}
        loop={true}
      >
        {!app
          ? technologies.map((tech, index) => (
              <SwiperSlide className="relative logoClient" key={index}>
                <motion.img
                  viewport={{ once: true }}
                  variants={variants}
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  src={tech.src}
                  alt={tech.alt}
                />
              </SwiperSlide>
            ))
          : apptechnologies.map((tech, index) => (
              <SwiperSlide className="relative logoClient" key={index}>
                <motion.img
                  viewport={{ once: true }}
                  variants={variants}
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  src={tech.src}
                  alt={tech.alt}
                />
              </SwiperSlide>
            ))}
      </Swiper>
    </>
  );
};

export default Tecnologias;
