import React from "react";
import { FlexBox, FlexCol } from "./FlexBox";
import { motion } from "framer-motion";
import "../styles/Nosotros.css";
import juancruz from "../assets/jcp.png";
import franco from "../assets/fv.png";
import martin from "../assets/mp.png";
import mein from "../assets/mein.png";
import cristian from "../assets/cristian.png";
import juampi from "../assets/juampi.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Nosotros = ({ variants, devLanding, aboutPage }) => {
  const [t] = useTranslation("global");

  return (
    <FlexBox
      id="nosotros"
      className={aboutPage ? "section bckGrad noPadTop" : "section bckGrad"}
      allign="center"
      direction="col"
    >
      <FlexBox
        className="container-lg"
        allign="center"
        justify="center"
        direction="column"
        wrap="wrap"
      >
        {!aboutPage && (
          <>
            <motion.h5
              viewport={{ once: true }}
              initial="initial"
              whileInView="fadeinup"
              className="primary shadow"
            >
              {devLanding ? "We are Suprads" : t("about.pretitle")}
            </motion.h5>
            <motion.h2
              className="textCenter white"
              viewport={{ once: true }}
              variants={variants}
              initial="initial"
              whileInView="fadeinup"
            >
              {devLanding ? "About us" : t("about.title")}
            </motion.h2>
            {devLanding ? (
              <>
                <motion.p
                  className="textCenter white"
                  viewport={{ once: true }}
                  variants={variants}
                  initial="initial"
                  whileInView="fadeinup"
                >
                  At Suprads, we are dedicated to delivering exceptional web
                  development services that help businesses achieve their online
                  goals. With years of experience in the industry, our team of
                  expert developers and designers bring a unique blend of
                  technical expertise, creativity, and innovation to every
                  project we work on.
                </motion.p>
                <motion.div
                  viewport={{ once: true }}
                  variants={variants}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  initial="initial"
                  whileInView="fadeinup"
                  style={{ display: "flex" }}
                >
                  {!aboutPage && (
                    <Link className="button shadow secondary" to={"/about-us"}>
                      See more of us
                    </Link>
                  )}
                </motion.div>
              </>
            ) : (
              <>
                <motion.p
                  className="textCenter white"
                  viewport={{ once: true }}
                  variants={variants}
                  initial="initial"
                  whileInView="fadeinup"
                >
                  {t("about.text")}
                </motion.p>
              </>
            )}
          </>
        )}
        <FlexBox
          className="boxNosotros"
          allign="center"
          justify="around"
          direction="row"
          wrap="wrap"
          gapCol="20px"
        >
          {/* --------------- Columna 1 --------------- */}
          <FlexCol
            className="relative"
            colWidth="1 1 250px"
            allign="center"
            direction="column"
            justify="center"
          >
            <svg
              className="blobCol"
              style={{ top: "0px", left: "-50px" }}
              viewBox="0 0 261 307"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M119.653 0.813101C156.465 5.52556 175.842 43.0962 201.198 70.1641C225.128 95.7085 259.132 116.675 260.916 151.611C262.747 187.454 234.127 215.251 209.966 241.816C184.014 270.35 158.216 305.303 119.653 306.928C80.2812 308.587 44.9471 281.312 21.3826 249.764C0.69056 222.062 4.86977 186.152 3.63946 151.611C2.333 114.933 -8.25684 75.319 14.1324 46.2169C38.3911 14.6846 80.1623 -4.24233 119.653 0.813101Z"
                fill="#7F7CFD"
              />
            </svg>
            <motion.div
              className="relative nosotrosColumn shadow"
              viewport={{ once: true }}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <motion.img
                viewport={{ once: true }}
                variants={variants}
                initial="initialIcon"
                whileInView="icon"
                className="imgNosotros"
                src={franco}
              />
              <motion.h3
                className="textCenter"
                viewport={{ once: true }}
                variants={variants}
                initial="initial"
                whileInView="fadeinup"
              >
                Franco
              </motion.h3>
              <motion.p
                className="textCenter"
                viewport={{ once: true }}
                variants={variants}
                initial="initial"
                whileInView="fadeinup"
              >
                Director & Founder
              </motion.p>
            </motion.div>
          </FlexCol>
          {/* --------------- Columna 2 --------------- */}
          <FlexCol
            className="relative"
            colWidth="1 1 250px"
            allign="center"
            direction="column"
            justify="center"
          >
            <svg
              className="blobCol"
              style={{
                bottom: "0px",
                left: "-30px",
                transform: "rotate(40deg)",
              }}
              viewBox="0 0 261 307"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M119.653 0.813101C156.465 5.52556 175.842 43.0962 201.198 70.1641C225.128 95.7085 259.132 116.675 260.916 151.611C262.747 187.454 234.127 215.251 209.966 241.816C184.014 270.35 158.216 305.303 119.653 306.928C80.2812 308.587 44.9471 281.312 21.3826 249.764C0.69056 222.062 4.86977 186.152 3.63946 151.611C2.333 114.933 -8.25684 75.319 14.1324 46.2169C38.3911 14.6846 80.1623 -4.24233 119.653 0.813101Z"
                fill="#7e7cfdad"
              />
            </svg>
            <motion.div
              className="relative nosotrosColumn shadow"
              viewport={{ once: true }}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <motion.img
                viewport={{ once: true }}
                variants={variants}
                initial="initialIcon"
                whileInView="icon"
                className="imgNosotros"
                src={juancruz}
              />
              <motion.h3
                className="textCenter"
                viewport={{ once: true }}
                variants={variants}
                initial="initial"
                whileInView="fadeinup"
              >
                Juan
              </motion.h3>
              <motion.p
                className="textCenter"
                viewport={{ once: true }}
                variants={variants}
                initial="initial"
                whileInView="fadeinup"
              >
                Project Manager
              </motion.p>
            </motion.div>
          </FlexCol>
          {/* --------------- Columna 3 --------------- */}
          <FlexCol
            className="relative"
            colWidth="1 1 250px"
            allign="center"
            direction="column"
            justify="center"
          >
            <svg
              className="blobCol"
              style={{
                top: "0px",
                right: "-60px",
                left: "initial",
                transform: "rotate(90deg)",
              }}
              viewBox="0 0 261 307"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M119.653 0.813101C156.465 5.52556 175.842 43.0962 201.198 70.1641C225.128 95.7085 259.132 116.675 260.916 151.611C262.747 187.454 234.127 215.251 209.966 241.816C184.014 270.35 158.216 305.303 119.653 306.928C80.2812 308.587 44.9471 281.312 21.3826 249.764C0.69056 222.062 4.86977 186.152 3.63946 151.611C2.333 114.933 -8.25684 75.319 14.1324 46.2169C38.3911 14.6846 80.1623 -4.24233 119.653 0.813101Z"
                fill="#7e7cfd6b"
              />
            </svg>
            <motion.div
              className="relative nosotrosColumn shadow"
              viewport={{ once: true }}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <motion.img
                viewport={{ once: true }}
                variants={variants}
                initial="initialIcon"
                whileInView="icon"
                className="imgNosotros"
                src={martin}
              />
              <motion.h3
                className="textCenter"
                viewport={{ once: true }}
                variants={variants}
                initial="initial"
                whileInView="fadeinup"
              >
                Martín
              </motion.h3>
              <motion.p
                className="textCenter"
                viewport={{ once: true }}
                variants={variants}
                initial="initial"
                whileInView="fadeinup"
              >
                Front End Team Lead
              </motion.p>
            </motion.div>
          </FlexCol>
          {/* --------------- Columna 4 --------------- */}
          <FlexCol
            className="relative"
            colWidth="1 1 250px"
            allign="center"
            direction="column"
            justify="center"
          >
            <svg
              className="blobCol"
              style={{
                top: "0px",
                right: "0px",
                left: "initial",
                transform: "rotate(130deg)",
              }}
              viewBox="0 0 261 307"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M119.653 0.813101C156.465 5.52556 175.842 43.0962 201.198 70.1641C225.128 95.7085 259.132 116.675 260.916 151.611C262.747 187.454 234.127 215.251 209.966 241.816C184.014 270.35 158.216 305.303 119.653 306.928C80.2812 308.587 44.9471 281.312 21.3826 249.764C0.69056 222.062 4.86977 186.152 3.63946 151.611C2.333 114.933 -8.25684 75.319 14.1324 46.2169C38.3911 14.6846 80.1623 -4.24233 119.653 0.813101Z"
                fill="#7e7cfd6b"
              />
            </svg>
            <motion.div
              className="relative nosotrosColumn shadow"
              viewport={{ once: true }}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <motion.img
                viewport={{ once: true }}
                variants={variants}
                initial="initialIcon"
                whileInView="icon"
                className="imgNosotros"
                src={mein}
              />
              <motion.h3
                className="textCenter"
                viewport={{ once: true }}
                variants={variants}
                initial="initial"
                whileInView="fadeinup"
              >
                Mein
              </motion.h3>
              <motion.p
                className="textCenter"
                viewport={{ once: true }}
                variants={variants}
                initial="initial"
                whileInView="fadeinup"
              >
                Back End Team Lead
              </motion.p>
            </motion.div>
          </FlexCol>
          {/* --------------- Columna 5 --------------- */}
          <FlexCol
            className="relative"
            colWidth="1 1 250px"
            allign="center"
            direction="column"
            justify="center"
          >
            <svg
              className="blobCol"
              style={{
                bottom: "-80px",
                right: "initial",
                left: "180px",
                transform: "rotate(90deg)",
              }}
              viewBox="0 0 261 307"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M119.653 0.813101C156.465 5.52556 175.842 43.0962 201.198 70.1641C225.128 95.7085 259.132 116.675 260.916 151.611C262.747 187.454 234.127 215.251 209.966 241.816C184.014 270.35 158.216 305.303 119.653 306.928C80.2812 308.587 44.9471 281.312 21.3826 249.764C0.69056 222.062 4.86977 186.152 3.63946 151.611C2.333 114.933 -8.25684 75.319 14.1324 46.2169C38.3911 14.6846 80.1623 -4.24233 119.653 0.813101Z"
                fill="#7e7cfdad"
              />
            </svg>
            <motion.div
              className="relative nosotrosColumn shadow"
              viewport={{ once: true }}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <motion.img
                viewport={{ once: true }}
                variants={variants}
                initial="initialIcon"
                whileInView="icon"
                className="imgNosotros"
                src={juampi}
              />
              <motion.h3
                className="textCenter"
                viewport={{ once: true }}
                variants={variants}
                initial="initial"
                whileInView="fadeinup"
              >
                Juampi
              </motion.h3>
              <motion.p
                className="textCenter"
                viewport={{ once: true }}
                variants={variants}
                initial="initial"
                whileInView="fadeinup"
              >
                Creative Director
              </motion.p>
            </motion.div>
          </FlexCol>
          {/* --------------- Columna 6 --------------- */}
          <FlexCol
            className="relative"
            colWidth="1 1 250px"
            allign="center"
            direction="column"
            justify="center"
          >
            <svg
              className="blobCol"
              style={{
                top: "70px",
                right: "-60px",
                left: "initial",
                transform: "rotate(-20deg)",
              }}
              viewBox="0 0 261 307"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M119.653 0.813101C156.465 5.52556 175.842 43.0962 201.198 70.1641C225.128 95.7085 259.132 116.675 260.916 151.611C262.747 187.454 234.127 215.251 209.966 241.816C184.014 270.35 158.216 305.303 119.653 306.928C80.2812 308.587 44.9471 281.312 21.3826 249.764C0.69056 222.062 4.86977 186.152 3.63946 151.611C2.333 114.933 -8.25684 75.319 14.1324 46.2169C38.3911 14.6846 80.1623 -4.24233 119.653 0.813101Z"
                fill="#7F7CFD"
              />
            </svg>
            <motion.div
              className="relative nosotrosColumn shadow"
              viewport={{ once: true }}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <motion.img
                viewport={{ once: true }}
                variants={variants}
                initial="initialIcon"
                whileInView="icon"
                className="imgNosotros"
                src={cristian}
              />
              <motion.h3
                className="textCenter"
                viewport={{ once: true }}
                variants={variants}
                initial="initial"
                whileInView="fadeinup"
              >
                Cristian
              </motion.h3>
              <motion.p
                className="textCenter"
                viewport={{ once: true }}
                variants={variants}
                initial="initial"
                whileInView="fadeinup"
              >
                Sales Manager
              </motion.p>
            </motion.div>
          </FlexCol>
        </FlexBox>
        {/* {!devLanding && (
          <FlexBox
            id="unite"
            className="relative boxCV shadow"
            direction="row"
            justify="around"
            allign="center"
            wrap="wrap"
            gapRow="20px"
            gapCol="30px"
          >
            <motion.h3
              className="relative titleServices white textCenter"
              viewport={{ once: true }}
              variants={variants}
              initial="initial"
              whileInView="fadeinup"
            >
              ¿Querés trabajar con nosotros?
            </motion.h3>
            <motion.a
              viewport={{ once: true }}
              variants={variants}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              initial="initial"
              whileInView="fadeinup"
              className="button shadow secondary"
              href="mailto:contact@suprads.com"
              target="_blank"
              rel="noreferrer"
            >
              Enviar mi CV
            </motion.a>
          </FlexBox>
        )} */}
      </FlexBox>
    </FlexBox>
  );
};

export default Nosotros;
