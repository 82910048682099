const workusList = [
  /* Item 1 */
  {
    id: 1,
    color: "#7851F5",
    title: "High-Performance Senior Developers",
    description: [
      "Our team of senior developers possess both programming and design skills. This unique combination allows us to deliver websites with optimal performance and UX/UI design. We don't just build websites, we build them with your business objectives in mind. With our expertise, we can help you achieve your online goals.",
    ],
  },
  /* Item 2 */
  {
    id: 2,
    color: "#7851F5",
    title: "Customizable Web Design & Development Plans",
    description: [
      "We understand that every business is different, which is why we offer customizable web design and development plans. Our plans are designed to meet the specific needs of your business. Whether you're a small business owner or a large corporation, we have the perfect plan for you.",
    ],
  },
  /* Item 3 */
  {
    id: 3,
    color: "#7851F5",
    title: "Key Tech Stack",
    description: [
      "Our team of developers use the latest technologies and programming languages to ensure that your website is cutting-edge and up-to-date. We pride ourselves on staying ahead of the curve and are always looking for new and innovative ways to improve our services.",
    ],
    tech: true,
  },
  /* Item 4 */
  {
    id: 4,
    color: "#7851F5",
    title: "5 Step Working Method",
    description: [
      "We follow a rigorous 5 step working method to ensure that your website is developed to the highest standards. Our method includes a thorough analysis of your business, the creation of a detailed project plan, development and testing of the website, and finally, the launch of the website. Our method ensures that your website is developed to meet your specific business goals.",
    ],
    step: true,
  },
  /* Item 5 */
  {
    id: 5,
    color: "#7851F5",
    title: "Dedicated Customer Support",
    description: [
      "We believe that great customer support is key to the success of any business. Our team is committed to providing you with the best possible service and support you need to achieve your online goals.",
    ],
  },
];

export default workusList;
