/* import { Link } from "react-router-dom"; */
import Tecnologias from "../Tecnologias";

const servicesList = {
  // @info
  //   List of services.
  // @type
  //   array
  // @default
  //   [{
  //     color: '',
  //     title: '',
  //     description: '',
  //     svgBlob: '',
  //     svg: '',
  //     adds: '',
  //     param: '',
  //   }]

  list: [
    /* Item 1 */
    {
      id: 1,
      color: "#7851F5",
      title: "Web Development",
      svgBlob: (
        <>
          <svg
            className="blobBox"
            viewBox="0 0 305 284"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M252.099 3.98902C287.375 19.2058 292.559 66.3482 299.825 104.093C306.424 138.371 308.115 174.176 290.685 204.436C273.24 234.723 241.185 250.634 208.424 262.777C171.057 276.628 131.516 291.545 94.3539 277.211C52.1671 260.938 13.8163 227.69 3.36039 183.678C-6.92619 140.378 11.591 92.9429 43.1071 61.4963C69.5697 35.0921 112.5 48.7434 148.529 38.8295C185.035 28.7841 217.348 -11.0013 252.099 3.98902Z"
              fill="#7851F5"
            />
          </svg>
        </>
      ),
      svg: (
        <>
          <svg
            className="iconBox"
            viewBox="0 0 95 95"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M35.625 87.0833H59.375C79.1667 87.0833 87.0833 79.1666 87.0833 59.375V35.625C87.0833 15.8333 79.1667 7.91663 59.375 7.91663H35.625C15.8333 7.91663 7.91667 15.8333 7.91667 35.625V59.375C7.91667 79.1666 15.8333 87.0833 35.625 87.0833Z"
              stroke="white"
              strokeWidth="5.9375"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              opacity="0.4"
              d="M38 35.5061L28.1438 45.3624C26.9958 46.5103 26.9958 48.4499 28.1438 49.5978L38 59.454"
              stroke="white"
              strokeWidth="5.9375"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              opacity="0.4"
              d="M57 35.5061L66.8562 45.3624C68.0042 46.5103 68.0042 48.4499 66.8562 49.5978L57 59.454"
              stroke="white"
              strokeWidth="5.9375"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </>
      ),
      adds: "",
      /* (
        <>
          <h4>Últimas webs desarrolladas:</h4>
          <div className="addsButtons">
            <Link to={"/web-development"}>See more</Link>
          </div>
        </>
      ) */ param: "web",
    },
    /* Item 2 */
    {
      id: 2,
      color: "#4285F4",
      title: "Google Ads",
      svgBlob: (
        <>
          <svg
            className="blobBox"
            viewBox="0 0 365 372"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M110.612 370.517C64.4746 363.305 44.0744 309.387 24.0742 267.162C5.91132 228.816 -6.93859 187.184 4.39975 146.279C15.7484 105.338 48.6541 76.8882 83.533 52.6583C123.316 25.0218 165.335 -4.5294 213.425 1.07265C268.017 7.43221 323.242 34.9363 348.898 83.5713C374.139 131.418 366.729 192.869 339.169 239.442C316.029 278.548 261.357 275.499 221.955 298.096C182.031 320.992 156.063 377.622 110.612 370.517Z"
              fill="#4285F4"
            />
          </svg>
        </>
      ),
      svg: (
        <>
          <svg
            className="iconBox"
            viewBox="0 0 95 95"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M85.4605 40.2958H48.2917V54.9813H70.1021C69.6667 58.623 67.2917 64.125 62.0271 67.8063C58.7021 70.1417 54.1896 71.7646 48.2917 71.7646C37.8417 71.7646 28.9355 64.8771 25.7688 55.298C24.9376 52.8438 24.4626 50.1917 24.4626 47.4604C24.4626 44.7292 24.9376 42.0772 25.7292 39.623C25.9667 38.9105 26.2438 38.1584 26.5605 37.4855C30.3209 29.0542 38.6334 23.1958 48.2917 23.1958C55.7334 23.1958 60.7209 26.4021 63.6105 29.0938L74.773 18.1688C67.9251 11.7958 58.9792 7.87708 48.2917 7.87708C32.8146 7.87708 19.4355 16.7438 12.9438 29.6875C10.2521 35.0709 8.70837 41.0875 8.70837 47.4604C8.70837 53.8333 10.2521 59.8501 12.9438 65.2334L12.9834 65.1938V65.2334C19.475 78.1772 32.8146 87.0437 48.2917 87.0437C58.9792 87.0437 67.9646 83.5209 74.4959 77.4646C81.9771 70.5771 86.2917 60.4042 86.2917 48.3312C86.2917 45.125 86.0146 42.75 85.4605 40.2958Z"
              stroke="white"
              strokeWidth="5.9375"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </>
      ),
      adds: "",
      param: "google",
    },
    /* Item 3 */
    {
      id: 3,
      color: "#485A96",
      title: "Meta Ads",
      svgBlob: (
        <>
          <svg
            className="blobBox"
            viewBox="0 0 398 401"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M197.842 0.778279C254.012 -7.57595 293.82 53.1435 331.171 95.7938C365.105 134.542 397.089 177.278 397.455 228.715C397.824 280.456 375.945 334.367 333.079 363.538C294.373 389.879 244.517 360.371 197.842 364.556C141.244 369.63 81.8255 423.519 35.8778 390.184C-10.051 356.862 -2.78925 283.748 10.6052 228.715C21.4396 184.201 70.0195 165.744 99.1302 130.306C134.954 86.6943 141.919 9.09577 197.842 0.778279Z"
              fill="#485A96"
            />
          </svg>
        </>
      ),
      svg: (
        <>
          <svg
            className="iconBox"
            viewBox="0 0 96 95"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="5.938"
              d="M59.835 87.083h-23.75c-19.792 0-27.708-7.916-27.708-27.708v-23.75c0-19.792 7.916-27.708 27.708-27.708h23.75c19.792 0 27.708 7.916 27.708 27.708v23.75c0 19.792-7.916 27.708-27.708 27.708z"
            ></path>
            <path
              fill="#EFEFEF"
              d="M26.688 53.781c0-8.912 4.456-18.212 9.687-18.212 2.906 0 5.231 1.743 8.913 6.975-3.488 5.425-5.62 8.719-5.62 8.719-4.65 7.362-6.2 8.912-8.718 8.912-2.519.194-4.262-2.131-4.262-6.394zm30.418-3.294l-3.294-5.425c-.774-1.356-1.743-2.712-2.518-3.874 2.906-4.457 5.231-6.782 8.137-6.782 5.813 0 10.463 8.719 10.463 19.569 0 4.069-1.357 6.394-4.069 6.394-2.713 0-3.681-1.744-8.719-9.882zm-8.331-13.175c-4.262-5.618-7.944-7.75-12.206-7.75-8.913 0-15.694 11.82-15.694 24.22 0 7.75 3.681 12.593 9.881 12.593 4.456 0 7.556-2.131 13.369-12.206 0 0 2.325-4.263 4.069-7.169.581.969 1.162 1.938 1.743 3.1l2.713 4.65c5.231 8.913 8.138 11.819 13.369 11.819 6.006 0 9.3-5.038 9.3-12.982-.194-13.175-7.169-24.025-15.5-24.025-4.457 0-7.944 3.488-11.044 7.75z"
            ></path>
          </svg>
        </>
      ),
      adds: "",
      param: "meta",
    },
    /* Item 4 */
    {
      id: 4,
      color: "#FFBC65",
      title: "Branding & Graphic Design",
      svgBlob: (
        <>
          <svg
            className="blobBox"
            viewBox="0 0 429 382"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M422.591 122.858C445.198 174.952 396.851 229.119 365.321 276.236C336.675 319.042 303.674 360.997 254.084 374.664C204.201 388.412 146.465 381.231 107.193 347.376C71.7326 316.806 87.3307 261.012 71.2081 217.01C51.6581 163.654 -15.7728 120.208 4.53443 67.198C24.8333 14.2099 97.3359 2.30082 153.96 0.995439C199.761 -0.0604387 230.163 42.0871 271.928 61.0337C323.326 84.3496 400.082 70.9929 422.591 122.858Z"
              fill="#FFBC65"
            />
          </svg>
        </>
      ),
      svg: (
        <>
          <svg
            className="iconBox"
            viewBox="0 0 96 95"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.4"
              d="M48.4202 31.2313L44.1847 38.5938C43.2347 40.2167 44.0264 41.5626 45.8868 41.5626H50.9139C52.8139 41.5626 53.566 42.9084 52.616 44.5313L48.4202 51.8938"
              stroke="white"
              strokeWidth="5.9375"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M33.774 71.4086V66.8169C24.6698 61.3148 17.1886 50.5877 17.1886 39.1877C17.1886 19.594 35.199 4.23566 55.5449 8.669C64.4907 10.6482 72.3282 16.5857 76.4053 24.7794C84.6782 41.4044 75.9698 59.0586 63.1844 66.7773V71.369C63.1844 72.5169 63.6199 75.169 59.3844 75.169H37.574C33.2198 75.2086 33.774 73.5065 33.774 71.4086Z"
              stroke="white"
              strokeWidth="5.9375"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              opacity="0.4"
              d="M34.5658 87.0831C43.6304 84.5102 53.2095 84.5102 62.2741 87.0831"
              stroke="white"
              strokeWidth="5.9375"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </>
      ),
      adds: "",
      param: "branding",
    },
    /* Item 5 */
    {
      id: 5,
      color: "#ED4545",
      title: "Software & App Development",
      svgBlob: (
        <>
          <svg
            className="blobBox"
            viewBox="0 0 436 364"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M16.4831 311.985C-18.8369 267.518 13.8435 202.683 32.1044 149.011C48.6947 100.249 69.713 51.1822 114.075 25.1463C158.7 -1.04356 216.328 -9.05113 263.024 13.4866C305.188 33.8369 304.562 91.7664 331.524 130.096C364.218 176.575 440.595 201.088 434.7 257.547C428.807 313.984 361.857 344.252 307.501 360.168C263.533 373.042 223.259 340.2 178.013 332.708C122.332 323.489 51.6478 356.257 16.4831 311.985Z"
              fill="#ED4545"
            />
          </svg>
        </>
      ),
      svg: (
        <>
          <svg
            className="iconBox"
            viewBox="0 0 95 96"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke="#FAFCFF"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="5.938"
              d="M39.583 67.594H24.581c-13.34 0-16.664-3.325-16.664-16.665V27.18c0-13.34 3.325-16.664 16.664-16.664h41.682c13.34 0 16.664 3.325 16.664 16.664M39.583 85.486V67.594"
            ></path>
            <path
              stroke="#FAFCFF"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="5.938"
              d="M7.917 51.76h31.666"
              opacity="0.4"
            ></path>
            <path
              stroke="#FAFCFF"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="5.938"
              d="M26.68 85.485h12.903M87.083 51.167v22.602c0 9.381-2.335 11.716-11.716 11.716H61.315c-9.382 0-11.717-2.335-11.717-11.716V51.167c0-9.382 2.335-11.717 11.717-11.717h14.052c9.381 0 11.716 2.335 11.716 11.717z"
            ></path>
            <path
              stroke="#FAFCFF"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="7.917"
              d="M68.26 72.74h.035"
              opacity="0.4"
            ></path>
          </svg>
        </>
      ),
      adds: (
        <>
          <Tecnologias app />
        </>
      ),
      param: "app",
    },
  ],
};

export default servicesList;
