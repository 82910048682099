import React from "react";
import { useState, useRef } from "react";
import { FlexBox, FlexCol } from "./FlexBox";
import logowhite from "../logowhite.svg";
import "../styles/Footer.css";
import { motion } from "framer-motion";
import axios from "axios";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const [t] = useTranslation("global");
  const [email, setEmail] = useState("");
  const urlHook = "https://hook.us1.make.com/hk8fsgx6csupp56pvwfmkf3v8k2vwlkw";
  const form = useRef(null);
  const [formLoading, setFormLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [error, setError] = useState("");

  const onSubmit = async (formData) => {
    formData.preventDefault();
    form.current.reset();
    setFormLoading(true);
    try {
      await axios
        .post(urlHook, email, {
          Accept: "application/json",
          "Content-Type": "application/json",
        })
        .then(function (response) {
          console.log(response);
          form.current.reset();
          setFormLoading(false);
          setIsSubmit(true);
        })
        .catch(function (error) {
          console.log(error);
          console.log("Hubo un problema");
          setError("We have a problem.. Try again");
          setFormLoading(false);
        });
      form.current.reset();
    } catch (error) {
      console.log(error);
      console.log("Hubo un problema");
      setError("We have a problem with send the data");
      setFormLoading(false);
    }
  };

  return (
    <>
      <FlexBox
        id="footer"
        className="section bckDark"
        allign="center"
        direction="col"
      >
        <FlexBox
          className="container-lg"
          allign="center"
          justify="center"
          direction="column"
          wrap="wrap"
        >
          <FlexBox
            className="colFooter"
            allign="center"
            justify="flex-start"
            direction="row"
            wrap="wrap"
            gapCol="20px"
            gapRow="20px"
          >
            <FlexCol
              className="colFooterChild"
              allign="start"
              self="baseline"
              justify="center"
              direction="column"
              wrap="wrap"
              colWidth="0 0 300px"
            >
              <img src={logowhite} alt="Logo Suprads" />
            </FlexCol>
            <FlexCol
              className="colFooterChild"
              allign="start"
              justify="center"
              direction="column"
              wrap="wrap"
              colWidth="0 0 250px"
            >
              <h5>Menu</h5>
              <ul>
                <li>
                  <a href="#inicio">{t("menu.home")}</a>
                </li>
                <li>
                  <a href="#servicios">{t("menu.services")}</a>
                </li>
                <li>
                  <a href="#nosotros">{t("menu.aboutus")}</a>
                </li>
                <li>
                  <a href="#contact">{t("menu.contact")}</a>
                </li>
              </ul>
            </FlexCol>
            <FlexCol
              className="colFooterChild"
              allign="start"
              self="baseline"
              justify="center"
              direction="column"
              wrap="wrap"
              colWidth="0 0 300px"
            >
              <h5>{t("footer.suscribe")}</h5>

              {/* ----------- Formulario ------------ */}

              <form
                ref={form}
                className="relative formNews"
                onSubmit={(e) => onSubmit(e)}
              >
                <input
                  type="text"
                  pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
                  name="email"
                  value={isSubmit ? "Thank you!" : email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder={
                    isSubmit
                      ? "Thank you!"
                      : formLoading
                      ? "Sending..."
                      : "john@doe.com"
                  }
                  required
                />
                <span className="error">{error}</span>

                {isSubmit ? (
                  <motion.svg
                    initial={{
                      scale: 0,
                    }}
                    transition={{ delay: 0.5 }}
                    animate={{
                      scale: 1,
                    }}
                    className="isSubmitNews"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.4"
                      d="M7.48 18.35L10.58 20.75C10.98 21.15 11.88 21.35 12.48 21.35H16.28C17.48 21.35 18.78 20.45 19.08 19.25L21.48 11.95C21.98 10.55 21.08 9.34997 19.58 9.34997H15.58C14.98 9.34997 14.48 8.84997 14.58 8.14997L15.08 4.94997C15.28 4.04997 14.68 3.04997 13.78 2.74997C12.98 2.44997 11.98 2.84997 11.58 3.44997L7.48 9.54997"
                      stroke="#292D32"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                    />
                    <path
                      d="M2.38 18.35V8.55002C2.38 7.15002 2.98 6.65002 4.38 6.65002H5.38C6.78 6.65002 7.38 7.15002 7.38 8.55002V18.35C7.38 19.75 6.78 20.25 5.38 20.25H4.38C2.98 20.25 2.38 19.75 2.38 18.35Z"
                      stroke="#292D32"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </motion.svg>
                ) : (
                  <button className="buttonNews" type="submit">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14.4301 5.93018L20.5001 12.0002L14.4301 18.0702"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        opacity="0.4"
                        d="M3.5 12H20.33"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                )}
              </form>

              {/* ----------- / Formulario ------------ */}

              {/* <h5>Follow us</h5>
              <FlexBox
                className="social"
                allign="center"
                justify="around"
                direction="row"
                wrap="wrap"
                gapCol="30px"
                gapRow="10px"
              >
                <a
                  href="https://www.facebook.com/Suprads.Marketing.Digital"
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg
                    width="27"
                    height="27"
                    viewBox="0 0 27 27"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.4"
                      d="M15.225 10.1136V13.3218H18.0852C18.2918 13.3218 18.4441 13.5066 18.4006 13.7133L17.9873 15.7795C17.9547 15.9318 17.8242 16.0405 17.6719 16.0405H15.225V23.9249H11.9625V16.0405H10.1138C9.9289 16.0405 9.78754 15.8992 9.78754 15.7143V13.648C9.78754 13.4631 9.9289 13.3218 10.1138 13.3218H11.9625V9.7874C11.9625 7.98215 13.4198 6.5249 15.225 6.5249H18.1613C18.3462 6.5249 18.4875 6.66626 18.4875 6.85114V9.46117C18.4875 9.64604 18.3462 9.7874 18.1613 9.7874H15.5513C15.3664 9.7874 15.225 9.92876 15.225 10.1136Z"
                      stroke="white"
                      strokeWidth="1.63125"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                    />
                    <path
                      d="M16.3125 23.9253H9.78755C4.35005 23.9253 2.17505 21.7503 2.17505 16.3128V9.78779C2.17505 4.35029 4.35005 2.17529 9.78755 2.17529H16.3125C21.75 2.17529 23.925 4.35029 23.925 9.78779V16.3128C23.925 21.7503 21.75 23.9253 16.3125 23.9253Z"
                      stroke="white"
                      strokeWidth="1.63125"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </a>
                <a
                  href="https://www.instagram.com/suprads.marketing/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.2375 22.9253H8.71248C3.27498 22.9253 1.09998 20.7503 1.09998 15.3128V8.78779C1.09998 3.35029 3.27498 1.17529 8.71248 1.17529H15.2375C20.675 1.17529 22.85 3.35029 22.85 8.78779V15.3128C22.85 20.7503 20.675 22.9253 15.2375 22.9253Z"
                      stroke="white"
                      strokeWidth="1.63125"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <rect
                      opacity="0.4"
                      x="7.62494"
                      y="7.7002"
                      width="8.7"
                      height="8.7"
                      rx="4.35"
                      stroke="white"
                      strokeWidth="1.63125"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                    />
                    <rect
                      opacity="0.4"
                      x="17.4125"
                      y="5.5249"
                      width="1.0875"
                      height="1.0875"
                      rx="0.54375"
                      stroke="white"
                      strokeWidth="1.63125"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                    />
                  </svg>
                </a>
                <a
                  href="https://wa.me/5492974576105"
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg
                    width="27"
                    height="27"
                    viewBox="0 0 27 27"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.39722 22.4354C10.0067 23.3815 11.8881 23.9253 13.9 23.9253C19.903 23.9253 24.775 19.0533 24.775 13.0503C24.775 7.04729 19.903 2.17529 13.9 2.17529C7.89696 2.17529 3.02496 7.04729 3.02496 13.0503C3.02496 15.0295 3.55787 16.8892 4.48224 18.4878L3.02496 23.9253L8.39722 22.4354Z"
                      stroke="white"
                      strokeWidth="1.63125"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      opacity="0.4"
                      d="M19.3375 16.4974C19.3375 16.6932 19.294 16.8998 19.1961 17.0956C19.0982 17.2913 18.9786 17.4762 18.8264 17.6502C18.5545 17.9438 18.2609 18.1613 17.9346 18.2918C17.6084 18.4332 17.2494 18.4984 16.8688 18.4984C16.3142 18.4984 15.7161 18.368 15.0962 18.0961C14.4654 17.8242 13.8456 17.4653 13.2257 17.0194C12.595 16.5627 12.0077 16.0516 11.4422 15.4969C10.8767 14.9314 10.3765 14.3333 9.91974 13.7134C9.47387 13.0935 9.11495 12.4737 8.85395 11.8538C8.59295 11.2339 8.46246 10.6358 8.46246 10.0703C8.46246 9.70057 8.52771 9.34169 8.65821 9.01544C8.78871 8.67832 8.99535 8.37382 9.28897 8.10195C9.63697 7.75395 10.0176 7.59082 10.42 7.59082C10.5722 7.59082 10.7245 7.62344 10.8659 7.68869C11.0072 7.75394 11.1377 7.85182 11.2356 7.99319L12.4971 9.77667C12.595 9.91805 12.6711 10.0377 12.7146 10.1573C12.7689 10.2769 12.7907 10.3857 12.7907 10.4944C12.7907 10.6249 12.7472 10.7554 12.6711 10.8859C12.595 11.0164 12.4971 11.1469 12.3666 11.2774L11.9534 11.7124C11.8881 11.7777 11.8663 11.8429 11.8663 11.9299C11.8663 11.9734 11.8772 12.0169 11.8881 12.0604C11.9099 12.1039 11.9207 12.1366 11.9316 12.1692C12.0295 12.3541 12.2034 12.5824 12.4427 12.8652C12.6928 13.1479 12.9539 13.4416 13.2366 13.7243C13.5302 14.0179 13.813 14.2789 14.1066 14.529C14.3893 14.7683 14.6286 14.9314 14.8135 15.0293C14.8461 15.0402 14.8787 15.0619 14.9113 15.0728C14.9548 15.0945 14.9983 15.0946 15.0527 15.0946C15.1506 15.0946 15.2159 15.0619 15.2811 14.9967L15.6944 14.5834C15.8357 14.4421 15.9662 14.3442 16.0858 14.2789C16.2163 14.2028 16.336 14.1593 16.4773 14.1593C16.5861 14.1593 16.6948 14.1811 16.8145 14.2354C16.9341 14.2898 17.0646 14.3551 17.1951 14.4529L19.0003 15.7362C19.1417 15.8341 19.2396 15.9537 19.3048 16.0842C19.3048 16.2147 19.3375 16.3452 19.3375 16.4974Z"
                      stroke="white"
                      strokeWidth="1.63125"
                      strokeMiterlimit="10"
                    />
                  </svg>
                </a>
              </FlexBox> */}
            </FlexCol>
          </FlexBox>
          <FlexBox
            className="subFooter"
            justify="space"
            allign="flex-end"
            direction="row"
            wrap="wrap"
          >
            <div className="privCopy">
              <p className="copy">© Suprads. All copyrights reserved - 2023</p>
              <Link className="privacy" to="/privacy-policy">
                Privacy Policy
              </Link>
            </div>
            <div className="hablemos">
              <h4>{t("footer.mailto")}</h4>
              <a
                href="mailto:contact@suprads.com"
                target="_blank"
                rel="noreferrer"
              >
                contact@suprads.com
              </a>
            </div>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </>
  );
};

export default Footer;
