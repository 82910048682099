import React from "react";
import { FlexBox, FlexCol } from "./FlexBox";
import { motion } from "framer-motion";
import "../styles/Hero.css";
import blob1 from "../assets/blob1.svg";
import blob2 from "../assets/blob2.svg";
import blob3 from "../assets/blob3.svg";
import blob4 from "../assets/blob4.svg";
import blob5 from "../assets/blob5.svg";
import blob6 from "../assets/blob6.svg";
import blob7 from "../assets/blob7.svg";
import { useTranslation } from "react-i18next";

const Hero = ({ windowDimenion }) => {
  const [t] = useTranslation("global");
  const initial = {
    initial:
      windowDimenion.winWidth >= 850
        ? {
            x: -30,
            y: -20,
            scale: 0,
          }
        : {
            x: -30,
            y: 50,
            scale: 0,
          },
  };
  return (
    <FlexBox
      className="hero"
      id="inicio"
      allign="center"
      justify="space"
      direction="col"
    >
      <FlexBox
        className="container-lg"
        allign="center"
        direction="row"
        wrap="wrap"
      >
        <FlexCol className="titleBox" direction="column" colWidth="5 1 55%">
          <motion.h5
            initial={{ x: -300, opacity: 0 }}
            animate={{
              x: 0,
              opacity: 1,
              transition: { type: "spring", duration: 0.8, delay: 0.7 },
            }}
            className="primary shadow"
          >
            {t("hero.subtitle")}
          </motion.h5>
          <motion.h1
            initial={{ x: -300, opacity: 0 }}
            animate={{
              x: 0,
              opacity: 1,
              transition: { type: "spring", duration: 0.8, delay: 1 },
            }}
          >
            {t("hero.title.part1")}
            <br />
          </motion.h1>
          <motion.h1
            initial={{ x: -300, opacity: 0 }}
            animate={{
              x: 0,
              opacity: 1,
              transition: { type: "spring", duration: 0.8, delay: 2 },
            }}
            className="titleResalt"
          >
            {t("hero.title.part2")}
          </motion.h1>

          <motion.h4
            initial={{ x: -300, opacity: 0 }}
            animate={{
              x: 0,
              opacity: 1,
              transition: { type: "spring", duration: 0.8, delay: 2.2 },
            }}
          >
            {t("hero.textOver")}
          </motion.h4>
        </FlexCol>

        <FlexCol
          className="relative"
          colWidth="1 1 360px"
          allign="center"
          direction="row"
          justify="center"
        >
          <motion.svg
            initial={{ scale: 0.5 }}
            animate={{ scale: 1 }}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            className="heroLogo"
            viewBox="0 0 222 286"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M218.046 177.108C205.827 166.692 193.646 156.276 181.502 145.86L42.7613 27.2879L13.8038 2.5369C12.6847 1.27087 11.1715 0.419108 9.50864 0.119244C7.84575 -0.180621 6.13033 0.0889272 4.63955 0.884336C2.97315 1.64345 1.62105 2.95623 0.813107 4.59951C0.00516015 6.24279 -0.208778 8.11516 0.207659 9.89834C2.46116 29.9295 4.72718 49.8855 7.00572 69.7664C14.7928 137.972 22.5424 206.203 30.2544 274.459C30.3229 277.987 31.6557 281.372 34.0102 283.999L37.766 285.201C39.3366 285.056 40.8532 284.554 42.2005 283.734C43.5477 282.914 44.6901 281.797 45.5406 280.469C57.1837 263.079 68.977 245.802 80.5826 228.413C88.3947 216.77 95.8313 204.826 108.789 198.028C109.611 197.803 110.415 197.514 111.193 197.164C118.971 193.448 127.513 191.608 136.131 191.794C161.258 191.794 186.422 191.794 211.586 191.794C213.322 191.984 215.079 191.741 216.698 191.085C218.317 190.43 219.748 189.383 220.863 188.038C221.201 186.873 221.577 185.747 221.915 184.582C221.832 183.141 221.456 181.731 220.81 180.44C220.164 179.148 219.262 178.002 218.159 177.071"
              fill="#7F7CFD"
            />
            <path
              d="M108.826 198.028C108.375 197.014 107.962 196 107.474 195.024C73.6716 131.3 39.8565 67.5379 6.02894 3.73877C5.50312 2.91249 5.08998 1.86085 4.63928 0.884336C6.13007 0.0889272 7.84549 -0.180621 9.50837 0.119244C11.1713 0.419108 12.6845 1.27087 13.8035 2.5369L42.761 27.2879L181.502 145.86C193.671 156.276 205.852 166.692 218.046 177.108C219.149 178.04 220.051 179.186 220.697 180.477C221.343 181.769 221.719 183.178 221.802 184.62C221.464 185.784 221.088 186.911 220.75 188.075C219.633 189.417 218.202 190.463 216.583 191.118C214.965 191.773 213.209 192.018 211.473 191.831C186.309 191.831 161.145 191.831 136.018 191.831C127.4 191.646 118.858 193.485 111.08 197.202C110.302 197.551 109.498 197.84 108.676 198.066"
              fill="#6058CC"
            />
          </motion.svg>

          <svg
            className="blob"
            viewBox="0 0 648 687"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M1 434.5C-11.0001 330.434 128.341 330.685 200.218 254.479C279.978 169.914 301.299 -24.6335 414.218 2.97903C523.632 29.7348 634.715 142.482 646.718 254.479C657.382 353.983 485.014 390.072 431.174 474.429C394.214 532.338 422.809 624.361 362.938 658.054C289.977 699.114 192.858 695.321 124.466 647.033C53.253 596.752 10.9862 521.101 1 434.5Z"
              fill="#7f7cfd"
              fillOpacity="0.2"
            />
          </svg>

          <motion.img
            className="blobLogo"
            src={blob1}
            alt="Blob 1"
            variants={initial}
            initial="initial"
            animate={
              windowDimenion.winWidth >= 850
                ? {
                    x: 200,
                    y: -150,
                    scale: 1,
                    transition: { type: "spring", duration: 0.8, delay: 0.3 },
                  }
                : {
                    x: 130,
                    y: 40,
                    scale: 0.8,
                    transition: { type: "spring", duration: 0.8, delay: 0.3 },
                  }
            }
          />
          <motion.img
            className="blobLogo"
            src={blob2}
            alt="Blob 2"
            variants={initial}
            initial="initial"
            animate={
              windowDimenion.winWidth >= 850
                ? {
                    x: -200,
                    y: 0,
                    scale: 1,
                    transition: { type: "spring", duration: 0.8, delay: 0.7 },
                  }
                : {
                    x: -130,
                    y: 0,
                    scale: 0.8,
                    transition: { type: "spring", duration: 0.8, delay: 0.7 },
                  }
            }
          />
          <motion.img
            className="blobLogo"
            src={blob3}
            alt="Blob 3"
            variants={initial}
            initial="initial"
            animate={
              windowDimenion.winWidth >= 850
                ? {
                    x: -100,
                    y: 320,
                    scale: 1,
                    transition: { type: "spring", duration: 0.8, delay: 0.5 },
                  }
                : {
                    x: -40,
                    y: 200,
                    scale: 0.8,
                    transition: { type: "spring", duration: 0.8, delay: 0.5 },
                  }
            }
          />
          <motion.img
            className="blobLogo"
            src={blob4}
            alt="Blob 4"
            variants={initial}
            initial="initial"
            animate={
              windowDimenion.winWidth >= 850
                ? {
                    x: 150,
                    y: 230,
                    scale: 1,
                    transition: { type: "spring", duration: 0.8, delay: 0.4 },
                  }
                : {
                    x: 120,
                    y: 160,
                    scale: 0.8,
                    transition: { type: "spring", duration: 0.8, delay: 0.4 },
                  }
            }
          />
          <motion.img
            className="blobLogo"
            src={blob5}
            alt="Blob 5"
            variants={initial}
            initial="initial"
            animate={
              windowDimenion.winWidth >= 850
                ? {
                    x: -250,
                    y: 150,
                    scale: 1,
                    transition: { type: "spring", duration: 0.8, delay: 0.9 },
                  }
                : {
                    x: -160,
                    y: 130,
                    scale: 0.8,
                    transition: { type: "spring", duration: 0.8, delay: 0.9 },
                  }
            }
          />
          <motion.img
            className="blobLogo"
            src={blob6}
            alt="Blob 6"
            variants={initial}
            initial="initial"
            animate={
              windowDimenion.winWidth >= 850
                ? {
                    x: 20,
                    y: -250,
                    scale: 1,
                    transition: { type: "spring", duration: 0.8, delay: 0.8 },
                  }
                : {
                    x: 70,
                    y: -100,
                    scale: 0.75,
                    transition: { type: "spring", duration: 0.8, delay: 0.8 },
                  }
            }
          />
          <motion.img
            className="blobLogo"
            src={blob7}
            alt="Blob 7"
            variants={initial}
            initial="initial"
            animate={
              windowDimenion.winWidth >= 850
                ? {
                    x: -230,
                    y: -200,
                    scale: 1,
                    transition: { type: "spring", duration: 0.8, delay: 0.5 },
                  }
                : {
                    x: -80,
                    y: -120,
                    scale: 0.8,
                    transition: { type: "spring", duration: 0.8, delay: 0.5 },
                  }
            }
          />
        </FlexCol>
      </FlexBox>
    </FlexBox>
  );
};

export default Hero;
