import React from "react";
import { FlexBox } from "./FlexBox";
import { motion } from "framer-motion";

const Plans = ({ variants }) => {
  const pricesCards = [
    {
      id: 1,
      name: "Design",
      price: 1950,
      included: [
        "Web Prototype Creation",
        "UI/UX",
        "Graphic design",
        "Marketing Visuals",
        "Source Files Included",
      ],
      color: "#ED4545",
      svgBlob: (
        <>
          <svg
            className="blobBox"
            viewBox="0 0 436 364"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M16.4831 311.985C-18.8369 267.518 13.8435 202.683 32.1044 149.011C48.6947 100.249 69.713 51.1822 114.075 25.1463C158.7 -1.04356 216.328 -9.05113 263.024 13.4866C305.188 33.8369 304.562 91.7664 331.524 130.096C364.218 176.575 440.595 201.088 434.7 257.547C428.807 313.984 361.857 344.252 307.501 360.168C263.533 373.042 223.259 340.2 178.013 332.708C122.332 323.489 51.6478 356.257 16.4831 311.985Z"
              fill="#ED4545"
            />
          </svg>
        </>
      ),
      svg: (
        <>
          <svg
            className="iconBox"
            viewBox="0 0 95 96"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.4">
              <path
                d="M42.5514 89.5625H52.5264C56.3264 89.5625 58.7805 86.8708 58.068 83.5854L56.4451 76.421H38.6326L37.0097 83.5854C36.2972 86.6729 38.9889 89.5625 42.5514 89.5625Z"
                stroke="white"
                strokeWidth="5.9375"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M56.4461 76.3816L63.2941 70.2856C67.1336 66.8815 67.2919 64.5062 64.244 60.6667L52.1711 45.3482C49.6378 42.1419 45.4816 42.1419 42.9482 45.3482L30.8753 60.6667C27.8274 64.5062 27.8273 67.0002 31.8253 70.2856L38.6732 76.3816"
                stroke="white"
                strokeWidth="5.9375"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M47.5386 44.5166V54.5313"
                stroke="white"
                strokeWidth="5.9375"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <path
              d="M49.5584 20.2917H45.6001C43.423 20.2917 41.6417 18.5105 41.6417 16.3334V12.3751C41.6417 10.198 43.423 8.41675 45.6001 8.41675H49.5584C51.7355 8.41675 53.5167 10.198 53.5167 12.3751V16.3334C53.5167 18.5105 51.7355 20.2917 49.5584 20.2917Z"
              stroke="white"
              strokeWidth="5.9375"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12.9438 56.5896H16.9021C19.0792 56.5896 20.8605 54.8083 20.8605 52.6313V48.6729C20.8605 46.4958 19.0792 44.7144 16.9021 44.7144H12.9438C10.7667 44.7144 8.98547 46.4958 8.98547 48.6729V52.6313C8.98547 54.8083 10.7667 56.5896 12.9438 56.5896Z"
              stroke="white"
              strokeWidth="5.9375"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M82.0562 56.5896H78.0979C75.9208 56.5896 74.1395 54.8083 74.1395 52.6313V48.6729C74.1395 46.4958 75.9208 44.7144 78.0979 44.7144H82.0562C84.2333 44.7144 86.0145 46.4958 86.0145 48.6729V52.6313C86.0145 54.8083 84.2333 56.5896 82.0562 56.5896Z"
              stroke="white"
              strokeWidth="5.9375"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M41.6417 14.5919C26.5604 16.3732 14.8438 29.1585 14.8438 44.7148"
              stroke="white"
              strokeWidth="5.9375"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M80.1563 44.7148C80.1563 29.1981 68.5188 16.4523 53.5167 14.5919"
              stroke="white"
              strokeWidth="5.9375"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </>
      ),
    },
    {
      id: 3,
      name: "Full Stack",
      price: 2900,
      included: [
        "Everything in the Design plan",
        "Full Scale Website",
        "Speed & SEO optimization",
        "Automations",
      ],
      color: "#7851F5",
      svgBlob: (
        <>
          <svg
            className="blobBox"
            viewBox="0 0 305 284"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M252.099 3.98902C287.375 19.2058 292.559 66.3482 299.825 104.093C306.424 138.371 308.115 174.176 290.685 204.436C273.24 234.723 241.185 250.634 208.424 262.777C171.057 276.628 131.516 291.545 94.3539 277.211C52.1671 260.938 13.8163 227.69 3.36039 183.678C-6.92619 140.378 11.591 92.9429 43.1071 61.4963C69.5697 35.0921 112.5 48.7434 148.529 38.8295C185.035 28.7841 217.348 -11.0013 252.099 3.98902Z"
              fill="#7851F5"
            />
          </svg>
        </>
      ),
      svg: (
        <svg
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="iconBox"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.185 14.1563C10.1348 14.0394 10.0629 13.9331 9.97292 13.8431L6.2069 10.0771C6.11694 9.98713 6.01064 9.91517 5.89371 9.86505L1.25373 7.87649C0.83599 7.69746 0.672219 7.19098 0.906053 6.80126V6.80126C2.15396 4.72142 4.37797 4.11816 6.12139 3.99363C7.00703 3.93037 7.81552 3.98582 8.40024 4.05599C8.42801 4.05932 8.4553 4.06269 8.4821 4.06608C8.7932 4.10549 9.10883 4.01246 9.3371 3.79745L11.4659 1.79233C12.4392 0.875549 13.7539 0.411455 15.087 0.514005L17.7226 0.716746C18.5833 0.782947 19.2671 1.46676 19.3333 2.32737L19.536 4.96299C19.6386 6.29615 19.1745 7.6108 18.2577 8.58412L16.2526 10.7129C16.0375 10.9412 15.9445 11.2568 15.9839 11.5679C15.9873 11.5947 15.9907 11.622 15.994 11.6498C16.0642 12.2345 16.1196 13.043 16.0564 13.9286C15.9319 15.672 15.3286 17.896 13.2487 19.144V19.144C12.859 19.3778 12.3526 19.214 12.1735 18.7963L10.185 14.1563ZM17.8377 2.44242C17.8282 2.31947 17.7305 2.22178 17.6076 2.21233L14.972 2.00959C14.0598 1.93942 13.1603 2.25696 12.4944 2.88423L9.88971 5.33757C9.82675 5.39687 9.77174 5.4641 9.72608 5.53757L7.86901 8.52502C7.62366 8.91972 7.68256 9.43144 8.01119 9.76006L10.2899 12.0388C10.6186 12.3674 11.1303 12.4264 11.525 12.181L14.5124 10.3239C14.5859 10.2783 14.6531 10.2233 14.7124 10.1603L17.1658 7.55565C17.793 6.88969 18.1106 5.9902 18.0404 5.07803L17.8377 2.44242ZM14.5689 13.6859C14.6106 12.9457 13.7942 12.5366 13.1646 12.928L12.4144 13.3943C11.9947 13.6552 11.8285 14.1834 12.0232 14.6375L12.5981 15.9792C12.875 16.6252 13.7068 16.7674 14.0062 16.1315C14.3424 15.4173 14.5037 14.6131 14.5602 13.8217C14.5634 13.7763 14.5663 13.731 14.5689 13.6859ZM7.12202 6.88543C7.51344 6.25577 7.10429 5.43936 6.36407 5.48114C6.31901 5.48368 6.27374 5.48657 6.22826 5.48981C5.43692 5.54634 4.63272 5.7076 3.9185 6.04382C3.2826 6.34317 3.42483 7.17501 4.07084 7.45187L5.41247 8.02685C5.86661 8.22149 6.39483 8.05527 6.65568 7.63564L7.12202 6.88543Z"
            fill="white"
          />
          <path
            d="M8.18322 13.8212L6.22882 11.8668C5.70317 11.3412 4.80811 11.5525 4.57303 12.2577L3.27961 16.138C3.14931 16.5289 3.52118 16.9007 3.91206 16.7704L7.79234 15.477C8.49757 15.2419 8.70887 14.3469 8.18322 13.8212Z"
            fill="white"
            opacity="0.4"
          />
          <path
            d="M14.2771 5.77302C14.6676 6.16355 15.3008 6.16355 15.6913 5.77302C16.0818 5.3825 16.0818 4.74934 15.6913 4.35881C15.3008 3.96829 14.6676 3.96829 14.2771 4.35881C13.8866 4.74934 13.8866 5.3825 14.2771 5.77302Z"
            fill="white"
            opacity="0.4"
          />
        </svg>
      ),
    },
    {
      id: 2,
      name: "Enterprise Web Solutions",
      enterprice: true,
      price: 0,
      included: [
        "Full Scale Website",
        "Speed Optimization",
        "Interactions/Animations",
        "Automations",
      ],
      color: "#4285F4",
      svgBlob: (
        <>
          <svg
            className="blobBox"
            viewBox="0 0 365 372"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M110.612 370.517C64.4746 363.305 44.0744 309.387 24.0742 267.162C5.91132 228.816 -6.93859 187.184 4.39975 146.279C15.7484 105.338 48.6541 76.8882 83.533 52.6583C123.316 25.0218 165.335 -4.5294 213.425 1.07265C268.017 7.43221 323.242 34.9363 348.898 83.5713C374.139 131.418 366.729 192.869 339.169 239.442C316.029 278.548 261.357 275.499 221.955 298.096C182.031 320.992 156.063 377.622 110.612 370.517Z"
              fill="#4285F4"
            />
          </svg>
        </>
      ),
      svg: (
        <>
          <svg
            className="iconBox"
            viewBox="0 0 95 95"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M35.625 87.0833H59.375C79.1667 87.0833 87.0833 79.1666 87.0833 59.375V35.625C87.0833 15.8333 79.1667 7.91663 59.375 7.91663H35.625C15.8333 7.91663 7.91667 15.8333 7.91667 35.625V59.375C7.91667 79.1666 15.8333 87.0833 35.625 87.0833Z"
              stroke="white"
              strokeWidth="5.9375"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              opacity="0.4"
              d="M38 35.5061L28.1438 45.3624C26.9958 46.5103 26.9958 48.4499 28.1438 49.5978L38 59.454"
              stroke="white"
              strokeWidth="5.9375"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              opacity="0.4"
              d="M57 35.5061L66.8562 45.3624C68.0042 46.5103 68.0042 48.4499 66.8562 49.5978L57 59.454"
              stroke="white"
              strokeWidth="5.9375"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </>
      ),
    },
  ];

  return (
    <FlexBox
      className="plansSec"
      id="plans"
      allign="center"
      justify="space"
      direction="row"
    >
      <FlexBox
        className="container-lg"
        allign="center"
        direction="column"
        wrap="wrap"
      >
        <motion.h5
          viewport={{ once: true }}
          initial="initial"
          whileInView="fadeinup"
          className="secondary shadow cardTitle"
        >
          Plans for you
        </motion.h5>
        <motion.h2
          className="white textCenter cardTitleh2"
          viewport={{ once: true }}
          variants={variants}
          initial="initial"
          whileInView="fadeinup"
        >
          Customizable Web Design & Development Plans
        </motion.h2>
        <FlexBox direction="row" wrap="wrap" className="cardPricesDiv">
          {pricesCards.map((card) => (
            <motion.div
              className={
                card.enterprice ? "enterpriceCard cardPrice" : "cardPrice"
              }
              key={card.id}
              style={{ borderColor: card.color }}
              initial={{ y: 300, opacity: 0 }}
              animate={{
                y: 0,
                opacity: 1,
                transition: {
                  type: "spring",
                  duration: 0.8,
                  delay: 1 + 0.3 * card.id,
                },
              }}
            >
              {card.svg}
              {card.svgBlob}
              <h3 className={card.enterprice ? "enterpriceTitle" : null}>
                {card.name}
              </h3>
              {card.id === 3 && <h5>Best choice</h5>}
              {card.enterprice ? (
                <>
                  <h4 style={{ color: card.color }} className="requestTitle">
                    Request a quote
                  </h4>
                  <p>
                    Our Enterprise Web Solutions plan is for complex,
                    large-scale web development projects. We work closely with
                    you to deliver a fully customized web solution that meets
                    the unique needs of your business
                  </p>
                  <a
                    className="button shadow"
                    style={{
                      background: card.color,
                    }}
                    href="#contacto"
                  >
                    Contact
                  </a>
                </>
              ) : (
                <>
                  <h4 style={{ color: card.color }}>
                    £
                    {card.price.toLocaleString("en-GB", {
                      style: "decimal",
                      currency: "GBP",
                    })}
                  </h4>
                  <span>One-time payment</span>
                  <ul>
                    {card.included.map((inc, index) => (
                      <li key={index}>{inc}</li>
                    ))}
                  </ul>
                  <a
                    className="button shadow"
                    style={{
                      background: card.color,
                    }}
                    href="#contacto"
                  >
                    Get started
                  </a>
                </>
              )}
            </motion.div>
          ))}
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
};

export default Plans;
