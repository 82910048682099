"use client";
import React from "react";
import { FlexBox, FlexCol } from "./FlexBox";
import { motion } from "framer-motion";
import "../styles/Escala.css";
import { useTranslation } from "react-i18next";

const Escala = ({ variants, devLanding }) => {
  const [t] = useTranslation("global");

  return (
    <FlexBox
      id="escala"
      className="section bckColor"
      allign="center"
      direction="col"
    >
      <FlexBox
        className="container-lg"
        allign="center"
        justify="center"
        direction="column"
        wrap="wrap"
      >
        <motion.h5
          viewport={{ once: true }}
          initial="initial"
          whileInView="fadeinup"
          className="secondary shadow"
        >
          {t("workus.preTitle")}
        </motion.h5>
        <motion.h2
          className="white textCenter"
          viewport={{ once: true }}
          variants={variants}
          initial="initial"
          whileInView="fadeinup"
        >
          {t("workus.title")}
        </motion.h2>
        <motion.p
          className="white textCenter"
          viewport={{ once: true }}
          variants={variants}
          initial="initial"
          whileInView="fadeinup"
        >
          {t("workus.subtitle")}
        </motion.p>
        <FlexBox
          className="escalaDiv"
          allign="center"
          justify="center"
          direction="row"
          wrap="wrap"
          gapCol="70px"
          gapRow="70px"
        >
          {/* --------------- Columna 3 --------------- */}
          <FlexCol
            className="relative escalaColumn"
            colWidth="1 1 250px"
            allign="center"
            direction="row"
            justify="center"
          >
            <svg
              className="blobCol"
              viewBox="0 0 207 196"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M157.471 8.67106C182.909 21.4774 204.586 45.9876 206.836 74.3841C208.931 100.839 183.311 118.733 167.901 140.343C154.213 159.539 145.413 183.713 123.215 191.649C99.12 200.263 72.2883 194.98 49.9497 182.51C26.536 169.44 1.48424 149.881 0.383964 123.083C-0.664832 97.5382 30.2284 85.1877 45.0999 64.3884C58.051 46.275 60.6851 20.738 80.6327 10.8473C104.501 -0.987162 133.682 -3.30572 157.471 8.67106Z"
                fill="#2B285A"
                fillOpacity="0.1"
              />
            </svg>
            <motion.svg
              viewport={{ once: true }}
              variants={variants}
              initial="initial"
              whileInView="fadeinup"
              className="icon"
              viewBox="0 0 82 82"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.4"
                d="M23.5067 62.0123V54.9398"
                stroke="white"
                strokeWidth="5.125"
                strokeLinecap="round"
              />
              <path
                opacity="0.4"
                d="M41 62.0126V47.8676"
                stroke="white"
                strokeWidth="5.125"
                strokeLinecap="round"
              />
              <path
                opacity="0.4"
                d="M58.4933 62.0127V40.761"
                stroke="white"
                strokeWidth="5.125"
                strokeLinecap="round"
              />
              <g opacity="0.4">
                <path
                  d="M58.4929 19.9862L56.9212 21.8312C48.2087 32.0129 36.5237 39.222 23.5062 42.4679"
                  stroke="white"
                  strokeWidth="5.125"
                  strokeLinecap="round"
                />
                <path
                  d="M48.484 19.9862H58.4948V29.9629"
                  stroke="white"
                  strokeWidth="5.125"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <path
                d="M30.75 75.1667H51.25C68.3334 75.1667 75.1667 68.3334 75.1667 51.25V30.75C75.1667 13.6667 68.3334 6.83337 51.25 6.83337H30.75C13.6667 6.83337 6.83337 13.6667 6.83337 30.75V51.25C6.83337 68.3334 13.6667 75.1667 30.75 75.1667Z"
                stroke="white"
                strokeWidth="5.125"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </motion.svg>
            <div>
              <motion.h4
                className="white"
                viewport={{ once: true }}
                variants={variants}
                initial="initial"
                whileInView="fadeinup"
              >
                {t("workus.about1.title")}
              </motion.h4>
              <motion.p
                className="colP white"
                viewport={{ once: true }}
                variants={variants}
                initial="initial"
                whileInView="fadeinup"
              >
                {devLanding
                  ? "Our main objective is the success of our clients, if they grow, we grow. they grow, we grow. So we seek to maximize their profitability profitability at every stage of our work."
                  : t("workus.about1.description")}
              </motion.p>
            </div>
          </FlexCol>
          {/* --------------- Columna 2 --------------- */}
          <FlexCol
            className="relative escalaColumn"
            colWidth="1 1 250px"
            allign="center"
            direction="row"
            justify="center"
          >
            <svg
              className="blobCol"
              viewBox="0 0 179 175"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M87.3449 2.61233C114.779 -2.97151 147.334 -0.664785 166.24 20.0245C184.83 40.3684 178.488 71.6647 175.503 99.0871C172.946 122.578 168.709 147.319 150.721 162.598C133.701 177.053 109.589 175.68 87.3449 173.965C67.2976 172.419 48.3028 166.622 33.0245 153.523C16.2866 139.173 -0.418436 121.157 0.00799419 99.0871C0.430213 77.2348 20.4338 62.9065 35.0881 46.719C50.9956 29.147 64.1435 7.33472 87.3449 2.61233Z"
                fill="#2B285A"
                fillOpacity="0.1"
              />
            </svg>
            <motion.svg
              viewport={{ once: true }}
              variants={variants}
              initial="initial"
              whileInView="fadeinup"
              className="icon"
              viewBox="0 0 82 82"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.4"
                d="M42.2643 30.34H60.2018"
                stroke="white"
                strokeWidth="4.875"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                opacity="0.4"
                d="M21.7982 30.34L24.3607 32.9025L32.0482 25.215"
                stroke="white"
                strokeWidth="4.875"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                opacity="0.4"
                d="M42.2643 54.2565H60.2018"
                stroke="white"
                strokeWidth="4.875"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                opacity="0.4"
                d="M21.7982 54.2565L24.3607 56.819L32.0482 49.1315"
                stroke="white"
                strokeWidth="4.875"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M30.7499 75.1668H51.2499C68.3333 75.1668 75.1666 68.3335 75.1666 51.2502V30.7502C75.1666 13.6668 68.3333 6.8335 51.2499 6.8335H30.7499C13.6666 6.8335 6.83325 13.6668 6.83325 30.7502V51.2502C6.83325 68.3335 13.6666 75.1668 30.7499 75.1668Z"
                stroke="white"
                strokeWidth="4.875"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </motion.svg>
            <div>
              <motion.h4
                className="white"
                viewport={{ once: true }}
                variants={variants}
                initial="initial"
                whileInView="fadeinup"
              >
                {t("workus.about2.title")}
              </motion.h4>
              <motion.p
                className="colP white"
                viewport={{ once: true }}
                variants={variants}
                initial="initial"
                whileInView="fadeinup"
              >
                {devLanding
                  ? "All our results are backed by data, thus working in a 100% transparent way."
                  : t("workus.about2.description")}
              </motion.p>
            </div>
          </FlexCol>
          {/* --------------- Columna 1 --------------- */}
          <FlexCol
            className="relative escalaColumn"
            colWidth="1 1 250px"
            allign="center"
            direction="row"
            justify="center"
          >
            <svg
              className="blobCol"
              viewBox="0 0 197 202"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M81.2545 6.48384C112.847 3.09132 148.715 -8.79562 172.792 11.8626C197.454 33.0219 200.759 71.3827 193.816 103.062C187.927 129.933 160.738 143.437 139.982 161.572C121.472 177.744 105.731 198.773 81.2545 201.405C54.6024 204.272 24.6068 196.941 8.31146 175.734C-7.06343 155.726 5.82139 128.26 5.68651 103.062C5.55022 77.6012 -8.18257 49.6895 7.52929 29.6094C23.9963 8.56413 54.6251 9.34344 81.2545 6.48384Z"
                fill="#2B285A"
                fillOpacity="0.1"
              />
            </svg>

            <motion.svg
              viewport={{ once: true }}
              variants={variants}
              initial="initial"
              whileInView="fadeinup"
              className="icon"
              viewBox="0 0 82 82"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.4"
                d="M25.5567 62.6957L36.1484 70.8957C37.515 72.2624 40.59 72.9457 42.64 72.9457H55.6234C59.7234 72.9457 64.165 69.8707 65.19 65.7707L73.39 40.829C75.0984 36.0457 72.0234 31.9457 66.8984 31.9457H53.2317C51.1817 31.9457 49.4734 30.2373 49.815 27.8457L51.5234 16.9123C52.2067 13.8373 50.1567 10.4207 47.0817 9.39568C44.3484 8.37068 40.9317 9.73735 39.565 11.7874L25.5567 32.629"
                stroke="white"
                strokeWidth="5.125"
                strokeMiterlimit="10"
              />
              <path
                d="M8.13168 62.6959V29.2126C8.13168 24.4293 10.1817 22.7209 14.965 22.7209H18.3817C23.165 22.7209 25.215 24.4293 25.215 29.2126V62.6959C25.215 67.4793 23.165 69.1876 18.3817 69.1876H14.965C10.1817 69.1876 8.13168 67.4793 8.13168 62.6959Z"
                stroke="white"
                strokeWidth="5.125"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </motion.svg>
            <div>
              <motion.h4
                className="white"
                viewport={{ once: true }}
                variants={variants}
                initial="initial"
                whileInView="fadeinup"
              >
                {t("workus.about3.title")}
              </motion.h4>
              <motion.p
                className="colP white"
                viewport={{ once: true }}
                variants={variants}
                initial="initial"
                whileInView="fadeinup"
              >
                {devLanding
                  ? "Our multidisciplinary work model allows us to find the best digital solutions for your business."
                  : t("workus.about3.description")}
              </motion.p>
            </div>
          </FlexCol>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
};

export default Escala;
