import React from "react";
import Header from "../components/Header";
import HeroDev from "../components/HeroDev";
import Nosotros from "../components/Nosotros";
import Plans from "../components/Plans";
import WorkUs from "../components/WorkUs";
import Form from "../components/Form";
import PortfolioSec from "../components/PortfolioSec";
import WpTg from "../components/WpTg";

const WebDev = ({ variants, windowDimenion }) => {
  return (
    <>
      <Header windowDimenion={windowDimenion} devLanding />
      <HeroDev variants={variants} devLanding />
      <WorkUs variants={variants} />
      <Plans variants={variants} />
      <Form variants={variants} devLanding />
      <PortfolioSec variants={variants} />
      {/* <Clientes variants={variants}/> */}
      <Nosotros variants={variants} devLanding />
      <WpTg />
    </>
  );
};

export default WebDev;
