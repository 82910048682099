import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";

const Privacy = ({ windowDimenion }) => {
  return (
    <>
      <Header windowDimenion={windowDimenion} />
      <div className="simplePage">
        <div className="container-lg flex flexCol relative flexWrap">
          <h1>Privacy Policy</h1>
          <p>
            Suprads Company is committed to protecting the privacy of our
            clients and website visitors. This Privacy Policy outlines the types
            of personal information we collect, how we use it, and the steps we
            take to ensure its security.
          </p>
          <h2>Collection of Personal Information:</h2>
          <p>
            We may collect personal information from you when you use our
            website or services, such as your name, email address, phone number,
            and payment information. We also collect information about your
            browsing habits and website usage. This information is used to
            improve our services and provide you with a better user experience.
          </p>
          <h2>Use of Personal Information:</h2>
          <p>
            The personal information we collect is used to provide you with the
            services you request, process payments, and communicate with you
            about your account and services. We may also use it for marketing
            purposes, such as sending you promotional offers and updates about
            our services.
          </p>
          <h2>Sharing of Personal Information:</h2>
          <p>
            We do not sell or rent your personal information to third parties.
            We may share your information with third-party service providers who
            assist us in providing our services, such as payment processors and
            marketing companies. These third parties are contractually obligated
            to keep your information confidential and use it only for the
            purpose of providing services to us.
          </p>
          <h2>Data Security:</h2>
          <p>
            We take the security of your personal information seriously and use
            appropriate measures to protect it from unauthorized access,
            alteration, disclosure, or destruction. These measures include
            encryption, firewalls, and restricted access to our systems.
          </p>
          <h2>EU General Data Protection Regulation (GDPR) Compliance:</h2>
          <p>
            We comply with the EU General Data Protection Regulation (GDPR) and
            have taken appropriate measures to protect the personal information
            of our clients and website visitors in the European Union. This
            includes implementing appropriate security measures and ensuring
            that our third-party service providers also comply with GDPR
            requirements.
          </p>
          <h2>Changes to This Privacy Policy:</h2>
          <p>
            We reserve the right to update this Privacy Policy from time to
            time. If we make any changes, we will notify you by posting the
            revised policy on our website. Your continued use of our services
            after the changes are posted constitutes your agreement to the new
            terms.
          </p>
          <h2>Contact Us:</h2>
          <p>
            If you have any questions or concerns about this Privacy Policy,
            please contact us at{" "}
            <a
              href="mailto:contact@suprads.com"
              target={"_blank"}
              rel="noreferrer"
            >
              contact@suprads.com
            </a>
            . We are committed to working with you to resolve any issues and
            protect your privacy.
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Privacy;
