import styled from "styled-components";

const FlexBox = styled.div`
  display: flex;
  ${(props) => (props.allign ? `align-items: ${props.allign};` : "")}
  ${(props) => (props.self ? `align-self: ${props.self};` : "")}
    ${(props) =>
    props.justify === "space" ? "justify-content: space-between;" : ""}
    ${(props) =>
    props.justify === "around" ? "justify-content: space-around;" : ""}
    ${(props) => (props.justify === "center" ? "justify-content: center;" : "")}
    ${(props) => (props.direction ? `flex-direction: ${props.direction};` : "")}
    ${(props) => (props.wrap ? `flex-wrap: ${props.wrap};` : "")}
    ${(props) => (props.gapCol ? `column-gap: ${props.gapCol};` : "")}
    ${(props) => (props.gapRow ? `row-gap: ${props.gapRow};` : "")}
`;
const FlexCol = styled(FlexBox)`
  flex: ${(props) => `${props.colWidth}`};
`;

export { FlexBox, FlexCol };
