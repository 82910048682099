import React from "react";
import { useState } from "react";
import logowhite from "../logowhite.svg";
import logob from "../logo.svg";
import styled from "styled-components";
import "../styles/Header.css";
import { motion } from "framer-motion";
import { FlexBox } from "./FlexBox";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
// Flags
import es from "../assets/flags/es.svg";
import en from "../assets/flags/en.svg";

const ButtonMenu = styled(motion.div)`
  justify-content: space-around;
  align-items: center;
  width: 41px;
  height: 41px;
  display: none;
  padding: 10px;
  position: relative;
  z-index: 999;
  @media (max-width: 850px) {
    flex-direction: column;
    display: flex;
  }
  span {
    height: 3px;
    width: stretch;
    background: var(--maincolor);
    border-radius: 20px;
  }
`;
const MenuNav = styled(motion.div)`
  @media (max-width: 850px) {
    width: 70%;
    position: fixed;
    top: 0;
    right: -10%;
    height: 100%;
    flex-direction: column;
    background-color: var(--maincolor);
    padding: 0px 20px;
    justify-content: center;
    align-items: flex-start;
    a {
      color: #fff;
      font-size: 27px;
      margin: 14px 20px;
    }
  }
`;
const variants = {
  open: {
    opacity: 1,
    x: 0,
    transition: {
      staggerChildren: 0.1,
    },
  },
  closed: {
    opacity: 0,
    x: "100%",
    transition: {
      delay: 0.6,
      staggerChildren: 0.1,
    },
  },
};

const item = {
  closed: {
    opacity: 0,
    x: 200,
  },
  open: {
    opacity: 1,
    x: 0,
  },
};

const Header = ({ windowDimenion, devLanding }) => {
  const [t, i18n] = useTranslation("global");
  const [switchActive, setSwitchActive] = useState(false);
  const flags = [en, es];
  const switchLang = (lang) => {
    i18n.changeLanguage(lang);
  };

  const menuList = [
    {
      id: 1,
      name: t("menu.home"),
      link: "#inicio",
    },
    {
      id: 2,
      name: t("menu.services"),
      link: "#servicios",
    },
    {
      id: 3,
      name: t("menu.aboutus"),
      link: "#nosotros",
    },
    {
      id: 4,
      name: t("menu.contact"),
      link: "#contact",
    },
  ];

  const [isOpen, toggleOpen] = useState(true);

  return (
    <>
      <nav className="header">
        <FlexBox
          className="container-lg"
          allign="center"
          justify="space"
          direction="row"
        >
          <Link to={"/"}>
            <img
              className="logo"
              src={devLanding ? logowhite : logob}
              alt="Logo Suprads"
            />
          </Link>

          <MenuNav
            className={devLanding ? "menu" : "menu hrefBlack"}
            allign="center"
            justify="space"
            direction="row"
            animate={
              windowDimenion.winWidth >= 850
                ? isOpen
                  ? "open"
                  : "closed"
                : isOpen
                ? "closed"
                : "open"
            }
            variants={variants}
            initial={windowDimenion.winWidth >= 850 && "open"}
          >
            {windowDimenion.winWidth > 850}
            {!devLanding ? (
              <>
                {menuList.map((menu) => (
                  <motion.a
                    variants={item}
                    href={menu.link}
                    onClick={() => toggleOpen(true)}
                    key={menu.id}
                  >
                    {menu.name}
                  </motion.a>
                ))}
                <div
                  className="switchLang"
                  onClick={() => setSwitchActive(!switchActive)}
                >
                  <button className="currentLang">
                    {t("current_lang")}{" "}
                    <img src={flags[t("flag") - 1]} alt="ES" />
                  </button>
                  <div className={switchActive ? "switchActive" : null}>
                    <button onClick={() => switchLang("en")}>
                      EN <img src={en} alt="EN" />
                    </button>
                    <button onClick={() => switchLang("es")}>
                      ES <img src={es} alt="ES" />
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <>
                <motion.a
                  variants={item}
                  href="/web-development#inicio"
                  onClick={() => toggleOpen(true)}
                >
                  Home
                </motion.a>
                <motion.a
                  variants={item}
                  href="/web-development#workus"
                  onClick={() => toggleOpen(true)}
                >
                  Why work with us?
                </motion.a>
                <motion.a
                  variants={item}
                  href="/web-development#plans"
                  onClick={() => toggleOpen(true)}
                >
                  Plans
                </motion.a>
                <motion.a
                  variants={item}
                  href="/web-development#portfolio"
                  onClick={() => toggleOpen(true)}
                >
                  Portfolio
                </motion.a>
                <motion.a
                  variants={item}
                  href="/web-development#nosotros"
                  onClick={() => toggleOpen(true)}
                >
                  About us
                </motion.a>
                <motion.a
                  variants={item}
                  href="/web-development#contact"
                  onClick={() => toggleOpen(true)}
                >
                  Contact
                </motion.a>
              </>
            )}
          </MenuNav>

          <ButtonMenu onClick={() => toggleOpen(!isOpen)}>
            <motion.span
              animate={isOpen ? "closed" : "open"}
              variants={{
                closed: {
                  x: "0%",
                  transition: {
                    transition: 0.6,
                  },
                },
                open: {
                  rotate: -45,
                  y: "7px",
                  backgroundColor: "#fff",
                },
              }}
            ></motion.span>
            <motion.span
              animate={isOpen ? "closed" : "open"}
              variants={{
                closed: {
                  x: 0,
                  opacity: 1,
                  transition: {
                    transition: 0.2,
                    delay: 0,
                  },
                },
                open: {
                  opacity: 0,
                  x: "-10%",
                  backgroundColor: "#fff",
                  transition: {
                    transition: 0.2,
                    delay: 0,
                  },
                },
              }}
              transition={{ duration: 0.1 }}
            ></motion.span>
            <motion.span
              animate={isOpen ? "closed" : "open"}
              variants={{
                closed: {
                  x: 0,
                  transition: {
                    transition: 0.6,
                  },
                },
                open: {
                  rotate: 45,
                  y: "-7px",
                  backgroundColor: "#fff",
                },
              }}
            ></motion.span>
          </ButtonMenu>
        </FlexBox>
      </nav>
    </>
  );
};

export default Header;
