import React from "react";
import { FlexBox, FlexCol } from "./FlexBox";
import { motion } from "framer-motion";
import "../styles/Hero.css";

const HeroDev = ({ variants, devLanding }) => {
  return (
    <FlexBox
      className="hero heroDev"
      id="inicio"
      allign="center"
      justify="space"
      direction="row"
    >
      <FlexBox
        className="container-lg"
        allign="center"
        direction="column"
        wrap="wrap"
      >
        <FlexCol className="titleBox" direction="column" colWidth="5 1 55%">
          <motion.h5
            initial={{ x: -300, opacity: 0 }}
            animate={{
              x: 0,
              opacity: 1,
              transition: { type: "spring", duration: 0.8, delay: 0.3 },
            }}
            className="primary shadow"
          >
            We are experts
          </motion.h5>
          <motion.h1
            initial={{ x: -300, opacity: 0 }}
            animate={{
              x: 0,
              opacity: 1,
              transition: { type: "spring", duration: 0.8, delay: 0.8 },
            }}
          >
            Design & Development of the website{" "}
            <motion.span
              initial={{ x: -300, opacity: 0 }}
              animate={{
                x: 0,
                opacity: 1,
                transition: { type: "spring", duration: 0.8, delay: 1.5 },
              }}
              className="titleResalt"
            >
              you need.
            </motion.span>
          </motion.h1>
          <motion.p
            initial={{ x: -300, opacity: 0 }}
            animate={{
              x: 0,
              opacity: 1,
              transition: { type: "spring", duration: 0.8, delay: 1.8 },
            }}
          >
            Looking for a high-performance website with exceptional UX/UI?
            Achieve your online goals with our <span>tailored solutions</span>{" "}
            that meet your <span>unique needs</span>.
          </motion.p>
          <motion.a
            initial={{ x: -300, opacity: 0 }}
            animate={{
              x: 0,
              opacity: 1,
              transition: { type: "spring", duration: 0.8, delay: 2.2 },
            }}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="button shadow"
            href="#contacto"
          >
            Request a Quote Today
          </motion.a>
        </FlexCol>
      </FlexBox>
    </FlexBox>
  );
};

export default HeroDev;
