import React from "react";
import { FlexBox, FlexCol } from "./FlexBox";
import { motion } from "framer-motion";
import "../styles/Hero.css";

const HeroSec = ({ section, title, spantitle, children }) => {
  return (
    <FlexBox
      className="hero heroDev noPadBot"
      id="inicio"
      allign="center"
      justify="space"
      direction="row"
    >
      <FlexBox
        className="container-lg"
        allign="center"
        direction="column"
        wrap="wrap"
      >
        <FlexCol className="titleBox" direction="column" colWidth="5 1 55%">
          <motion.h5
            initial={{ x: -300, opacity: 0 }}
            animate={{
              x: 0,
              opacity: 1,
              transition: { type: "spring", duration: 0.8, delay: 0.3 },
            }}
            className="primary shadow"
          >
            {section}
          </motion.h5>
          <motion.h1
            initial={{ x: -300, opacity: 0 }}
            animate={{
              x: 0,
              opacity: 1,
              transition: { type: "spring", duration: 0.8, delay: 0.8 },
            }}
          >
            {title}{" "}
            <motion.span
              initial={{ x: -300, opacity: 0 }}
              animate={{
                x: 0,
                opacity: 1,
                transition: { type: "spring", duration: 0.8, delay: 1.5 },
              }}
              className="titleResalt"
            >
              {spantitle}
            </motion.span>
          </motion.h1>
          {children}
        </FlexCol>
      </FlexBox>
    </FlexBox>
  );
};

export default HeroSec;
