import React from "react";
import Header from "../components/Header";
import HeroSec from "../components/HeroSec";
import Nosotros from "../components/Nosotros";
import { motion } from "framer-motion";
import WpTg from "../components/WpTg";

const AboutUs = ({ variants, windowDimenion }) => {
  return (
    <>
      <Header windowDimenion={windowDimenion} devLanding />
      <HeroSec
        section={"About Us"}
        title={"Professional team ready "}
        spantitle={"for your project."}
        devLanding
      >
        <motion.p
          initial={{ x: -300, opacity: 0 }}
          animate={{
            x: 0,
            opacity: 1,
            transition: { type: "spring", duration: 0.8, delay: 1.8 },
          }}
        >
          At Suprads, we are dedicated to delivering exceptional web development
          services that help businesses achieve their online goals. With years
          of experience in the industry, our team of expert developers and
          designers bring a unique blend of technical expertise, creativity, and
          innovation to every project we work on.
        </motion.p>
        <motion.p
          initial={{ x: -300, opacity: 0 }}
          animate={{
            x: 0,
            opacity: 1,
            transition: { type: "spring", duration: 0.8, delay: 2 },
          }}
        >
          We pride ourselves on staying ahead of the curve when it comes to the
          latest web technologies and programming languages, enabling us to
          deliver cutting-edge, high-performance websites with exceptional UX/UI
          design. Our team takes a collaborative approach to every project,
          working closely with clients to understand their specific business
          needs and goals, and delivering tailored solutions that help them
          achieve success online.
        </motion.p>
        <motion.p
          initial={{ x: -300, opacity: 0 }}
          animate={{
            x: 0,
            opacity: 1,
            transition: { type: "spring", duration: 0.8, delay: 2.2 },
          }}
        >
          At Suprads, we believe in providing our clients with exceptional
          service and support at every stage of the web development process.
          From initial consultation and project planning to development,
          testing, and launch, our team is dedicated to delivering the highest
          quality work and ensuring our clients are completely satisfied with
          the end result.
        </motion.p>
        <motion.p
          initial={{ x: -300, opacity: 0 }}
          animate={{
            x: 0,
            opacity: 1,
            transition: { type: "spring", duration: 0.8, delay: 2.3 },
          }}
        >
          If you're looking for a web development partner that combines
          technical expertise, creativity, and innovation with exceptional
          service and support, look no further than Suprads. We are committed to
          helping businesses succeed online and look forward to working with
          you.
        </motion.p>
      </HeroSec>
      {/* <Clientes variants={variants}/> */}
      <Nosotros variants={variants} devLanding aboutPage />
      <WpTg />
    </>
  );
};

export default AboutUs;
