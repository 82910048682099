import React from "react";
import { FlexBox } from "./FlexBox";
import { motion } from "framer-motion";
import { Autoplay, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "../styles/WorkUs.css";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import workusList from "./workuslist/listWorkus";
import Tecnologias from "./Tecnologias";
import Steps from "./Steps";

const WorkUs = ({ variants }) => {
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);

  return (
    <FlexBox
      id="workus"
      className={"section relative workus noPadTop"}
      allign="center"
      direction="col"
    >
      <FlexBox
        className="container-lg"
        allign="center"
        justify="center"
        direction="column"
        wrap="wrap"
      >
        <svg
          className="blobCol"
          style={{
            top: "40px",
            right: "-60px",
            left: "initial",
            transform: "rotate(90deg)",
          }}
          viewBox="0 0 261 307"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M119.653 0.813101C156.465 5.52556 175.842 43.0962 201.198 70.1641C225.128 95.7085 259.132 116.675 260.916 151.611C262.747 187.454 234.127 215.251 209.966 241.816C184.014 270.35 158.216 305.303 119.653 306.928C80.2812 308.587 44.9471 281.312 21.3826 249.764C0.69056 222.062 4.86977 186.152 3.63946 151.611C2.333 114.933 -8.25684 75.319 14.1324 46.2169C38.3911 14.6846 80.1623 -4.24233 119.653 0.813101Z"
            fill="#7e7cfd6b"
          />
        </svg>
        <svg
          className="blobCol"
          style={{
            bottom: "-30px",
            left: "-30px",
            transform: "rotate(40deg)",
          }}
          viewBox="0 0 261 307"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M119.653 0.813101C156.465 5.52556 175.842 43.0962 201.198 70.1641C225.128 95.7085 259.132 116.675 260.916 151.611C262.747 187.454 234.127 215.251 209.966 241.816C184.014 270.35 158.216 305.303 119.653 306.928C80.2812 308.587 44.9471 281.312 21.3826 249.764C0.69056 222.062 4.86977 186.152 3.63946 151.611C2.333 114.933 -8.25684 75.319 14.1324 46.2169C38.3911 14.6846 80.1623 -4.24233 119.653 0.813101Z"
            fill="#7e7cfdad"
          />
        </svg>
        <motion.h3
          className="relative"
          viewport={{ once: true }}
          variants={variants}
          initial="initial"
          whileInView="fadeinup"
        >
          Why work with us?
        </motion.h3>
        <Swiper
          className="carruWork"
          modules={[Navigation, Autoplay]}
          spaceBetween={10}
          navigation={{
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
          }}
          autoplay={{
            delay: 5000,
          }}
          onBeforeInit={(swiper) => {
            swiper.params.navigation.prevEl = navigationPrevRef.current;
            swiper.params.navigation.nextEl = navigationNextRef.current;
          }}
          grabCursor={true}
          breakpoints={{
            320: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
          }}
          loop
        >
          {workusList.map((work, index) => (
            <SwiperSlide
              className="relative boxWorkDiv shadow"
              key={index}
              virtualIndex={index}
              color={work.color}
            >
              <div className="boxWork">
                <h3 style={{ color: work.color }}>{work.title}</h3>
                <p>{work.description}</p>
                {work.tech && <Tecnologias />}
                {work.step && <Steps />}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="navSlider">
          <div className="swiper-button-prev" ref={navigationPrevRef} />
          <div className="swiper-button-next" ref={navigationNextRef} />
        </div>
      </FlexBox>
    </FlexBox>
  );
};

export default WorkUs;
