import "./App.css";
import { useState, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import WebDev from "./pages/WebDev";
import Home from "./pages/Home";
import Privacy from "./pages/Privacy";
import AboutUs from "./pages/AboutUs";
import ScrollToTop from "./ScrollToTop";
import Portfolio from "./pages/Portfolio";
import Contact from "./pages/Contact";

// Global Translations
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import global_es from "./translations/es/global.json";
import global_en from "./translations/en/global.json";
import WpTg from "./components/WpTg";

i18next.use(LanguageDetector).init({
  interpolation: { escapeValue: false },
  resources: {
    es: {
      global: global_es,
    },
    en: {
      global: global_en,
    },
  },
  detection: {
    order: ["navigator", "htmlTag", "path", "subdomain"],
    lookupFromPathIndex: 0,
    lookupFromSubdomainIndex: 0,
    caches: ["localStorage"],
  },
});

function App() {
  const [windowDimenion, detectHW] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  });

  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", detectSize);

    return () => {
      window.removeEventListener("resize", detectSize);
    };
  }, [windowDimenion]);

  /* Variants motions */

  const variants = {
    fadeinup: {
      y: 0,
      opacity: 1,
      transition: { type: "spring", duration: 0.8, delay: 0.3 },
    },
    initial: {
      y: -70,
      opacity: 0,
    },
    initialIcon: {
      scale: 0,
    },
    icon: {
      scale: 1,
      transition: { type: "spring", duration: 0.4, delay: 0.6 },
    },
  };

  return (
    <div className="App">
      <I18nextProvider i18n={i18next}>
        <BrowserRouter>
          <ScrollToTop />
          <Routes>
            <Route
              exact
              path="/"
              element={
                <Home variants={variants} windowDimenion={windowDimenion} />
              }
            />
            <Route
              exact
              path="/web-development"
              element={
                <WebDev variants={variants} windowDimenion={windowDimenion} />
              }
            />
            <Route
              exact
              path="/about-us"
              element={
                <AboutUs variants={variants} windowDimenion={windowDimenion} />
              }
            />
            <Route
              exact
              path="/portfolio"
              element={
                <Portfolio
                  variants={variants}
                  windowDimenion={windowDimenion}
                />
              }
            />
            <Route
              exact
              path="/contact"
              element={
                <Contact variants={variants} windowDimenion={windowDimenion} />
              }
            />
            <Route
              exact
              path="/privacy-policy"
              element={<Privacy windowDimenion={windowDimenion} />}
            />
            <Route
              exact
              path="*"
              element={
                <WebDev variants={variants} windowDimenion={windowDimenion} />
              }
            />
          </Routes>
          <WpTg />
        </BrowserRouter>
      </I18nextProvider>
    </div>
  );
}

export default App;
