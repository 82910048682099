import React from "react";
import { FlexBox } from "./FlexBox";
import { motion } from "framer-motion";
import servicesList from "./services/ListServices";
import { Autoplay, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "../styles/Servicios.css";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import Form from "./Form";
import { useTranslation } from "react-i18next";

const Servicios = ({ variants, noServices, devLanding }) => {
  const [t] = useTranslation("global");

  const [currentSlide, setCurrentSlide] = React.useState(0);

  const dinaBack = {
    background: servicesList.list[currentSlide].color,
  };
  return (
    <FlexBox
      id="servicios"
      className={devLanding ? "section serviciosDev" : "section"}
      allign="center"
      direction="col"
      style={dinaBack}
    >
      <FlexBox
        className="container-lg"
        allign="left"
        justify="center"
        direction="column"
        wrap="wrap"
      >
        {!noServices && (
          <>
            <motion.h3
              className="relative titleServices"
              viewport={{ once: true }}
              variants={variants}
              initial="initial"
              whileInView="fadeinup"
            >
              {t("services.title.part1")}
              <span className="gradSpan"> {t("services.title.part2")}</span>
            </motion.h3>
            <Swiper
              className="carru-container"
              modules={[Navigation, Autoplay]}
              spaceBetween={10}
              autoplay={{
                delay: 5000,
              }}
              grabCursor={true}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                },
                768: {
                  slidesPerView: 2,
                },
                1024: {
                  slidesPerView: 4,
                },
              }}
              watchSlidesProgress={true}
              loop
              onSlideChange={(swiper) => {
                setCurrentSlide(swiper.realIndex);
              }}
            >
              {servicesList.list.map((service) => (
                <SwiperSlide
                  className="relative boxP shadow"
                  key={service.id}
                  virtualIndex={service.id}
                  color={service.color}
                >
                  <div className="box">
                    {service.svgBlob}
                    {service.svg}
                    <h3 className="titleCard" style={{ color: service.color }}>
                      {t(`services.cards.${service.id - 1}.title`)}
                    </h3>
                    <p className="pCard">
                      {t(`services.cards.${service.id - 1}.description`)}
                    </p>
                    <div className="adds">{service.adds}</div>
                  </div>
                  <a
                    className="button shadow"
                    style={{
                      background: service.color,
                    }}
                    href="#contacto"
                  >
                    {t("services.button")}
                  </a>
                </SwiperSlide>
              ))}
            </Swiper>
            {/* Form Sec */}
          </>
        )}
        <Form variants={variants} color={dinaBack} devLanding={devLanding} />
      </FlexBox>
    </FlexBox>
  );
};

export default Servicios;
