import React from "react";
import { FlexBox } from "./FlexBox";
import { motion } from "framer-motion";
import "../styles/CasesPortfolio.css";
// Images
import magma from "../assets/cases/magma.png";
import leiva from "../assets/cases/leiva.png";
import econ from "../assets/cases/econ.png";
import plastiflex from "../assets/cases/plastiflex.png";
import mountainstore from "../assets/cases/mountainstore.png";
import mareas from "../assets/cases/mareas.png";
import marife from "../assets/cases/marife.png";
import cryptogames from "../assets/cases/cryptogames.png";
import creators from "../assets/cases/creators.png";
import baby from "../assets/cases/baby.png";
import woocar from "../assets/cases/woocar.png";
import block from "../assets/cases/block.png";

const CasesPortfolio = ({ variants }) => {
  const cases = [
    {
      id: 1,
      name: "Magma Energy",
      web: "https://magmaenergy.netlify.app/",
      img: magma,
    },

    {
      id: 3,
      name: "Econ Pay",
      web: "https://econpay.netlify.app/",
      img: econ,
    },

    {
      id: 5,
      name: "Mountain Store Catedral",
      web: "https://mountainstore.netlify.app/",
      img: mountainstore,
    },
    {
      id: 6,
      name: "Edificio Mareas",
      web: "https://edificiomareas.com/",
      img: mareas,
    },
    {
      id: 7,
      name: "Galería Marifé Marcó",
      web: "https://galeriamarifemarco.com",
      img: marife,
    },
    {
      id: 8,
      name: "Crypto Games Zone",
      web: "https://cryptogameszone.com/",
      img: cryptogames,
    },
    {
      id: 9,
      name: "Creators",
      web: "https://becreators.netlify.app/",
      img: creators,
    },
    {
      id: 10,
      name: "Baby Pixel Gator",
      web: "https://babypixelgator.netlify.app",
      img: baby,
    },
    {
      id: 11,
      name: "Woocar Blog",
      web: "https://blog.woocar.io/",
      img: woocar,
    },
    {
      id: 12,
      name: "Block Solutions",
      web: "https://blocksolutions.tech/",
      img: block,
    },
    {
      id: 2,
      name: "Galletitas Leiva",
      web: "https://www.galletitasleiva.com.ar/",
      img: leiva,
    },
    {
      id: 4,
      name: "Plastiflex",
      web: "https://plastiflex.com.ar/",
      img: plastiflex,
    },
  ];

  return (
    <FlexBox
      className="casesSec section"
      id="cases"
      allign="center"
      justify="space"
      direction="row"
    >
      <FlexBox
        className="container-lg"
        allign="center"
        direction="column"
        wrap="wrap"
      >
        <motion.h2
          className="white textCenter cardTitleh2"
          viewport={{ once: true }}
          variants={variants}
          initial="initial"
          whileInView="fadeinup"
        >
          Success Cases
        </motion.h2>
        <FlexBox direction="row" wrap="wrap" className="cardCasesDiv">
          {cases.map((card) => (
            <motion.div
              className={"cardCase"}
              key={card.id}
              style={{ backgroundImage: `url(${card?.img})` }}
              initial={{ y: 300, opacity: 0 }}
              animate={{
                y: 0,
                opacity: 1,
                transition: {
                  type: "spring",
                  duration: 0.4,
                  delay: 1 + 0.2 * card.id,
                },
              }}
            >
              <a
                className="button shadow"
                href={card.web}
                target="_blank"
                rel="noreferrer"
              >
                Visit site
              </a>
            </motion.div>
          ))}
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
};

export default CasesPortfolio;
