import React from "react";
import Escala from "../components/Escala";
import Header from "../components/Header";
import Hero from "../components/Hero";
import Nosotros from "../components/Nosotros";
import Servicios from "../components/Servicios";
import Footer from "../components/Footer";

const Home = ({ variants, windowDimenion }) => {
  return (
    <>
      <Header windowDimenion={windowDimenion} />
      <Hero windowDimenion={windowDimenion} />
      <Servicios variants={variants} />
      <Escala variants={variants} />
      {/* <Clientes variants={variants}/> */}
      <Nosotros variants={variants} />
      <Footer />
    </>
  );
};

export default Home;
