import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import "../styles/Steps.css";

const Steps = ({ variants }) => {
  const stepList = [
    {
      id: 1,
      title: "Briefing",
    },
    {
      id: 2,
      title: "Material & Acceses",
    },
    {
      id: 3,
      title: "Development",
    },
    {
      id: 4,
      title: "Check & Changes",
    },
    {
      id: 5,
      title: "Proyect Completion",
    },
  ];
  const [counterStep, setcounterStep] = useState(1);
  useEffect(() => {
    setTimeout(() => {
      if (counterStep === stepList.length) {
        setcounterStep(1);
      } else {
        setcounterStep(counterStep + 1);
      }
    }, 2000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counterStep]);
  return (
    <motion.div
      className="stepsDiv"
      viewport={{ once: true }}
      variants={variants}
      initial="initial"
      whileInView="fadeinup"
    >
      <span className="line"></span>
      {stepList.map((step) => (
        <motion.div
          className="stepPoint"
          key={step.id}
          whileHover={{ scale: 1.2, y: 1 }}
          animate={
            step.id === counterStep
              ? {
                  scale: 1.2,
                  y: 1,
                }
              : { scale: 1, y: 0 }
          }
        >
          <span></span>
          <motion.h3
            initial={{ opacity: 0, y: -20 }}
            animate={
              step.id === counterStep
                ? {
                    opacity: 1,
                    y: 0,
                  }
                : {
                    opacity: 0,
                    y: -20,
                  }
            }
            transition={{ type: "spring", stiffness: 100 }}
          >
            {step.title}
          </motion.h3>
        </motion.div>
      ))}
    </motion.div>
  );
};

export default Steps;
