import React from "react";
import Header from "../components/Header";
import HeroSec from "../components/HeroSec";
import { motion } from "framer-motion";
import CasesPortfolio from "../components/CasesPortfolio";
import WpTg from "../components/WpTg";

const Portfolio = ({ variants, windowDimenion }) => {
  return (
    <>
      <Header windowDimenion={windowDimenion} devLanding />
      <HeroSec
        section={"Portfolio"}
        title={"From Concept"}
        spantitle={"to Launch."}
        variants={variants}
        devLanding
      >
        <motion.p
          initial={{ x: -300, opacity: 0 }}
          animate={{
            x: 0,
            opacity: 1,
            transition: { type: "spring", duration: 0.8, delay: 1.8 },
          }}
        >
          At Suprads, we are committed to helping businesses achieve their
          online goals through exceptional web development services. Our
          portfolio showcases the quality of our work and our ability to create
          custom, high-performance websites with exceptional UX/UI design.
        </motion.p>
        <motion.p
          initial={{ x: -300, opacity: 0 }}
          animate={{
            x: 0,
            opacity: 1,
            transition: { type: "spring", duration: 0.8, delay: 2 },
          }}
        >
          We take a collaborative approach to every project, working closely
          with clients to understand their specific business needs and goals.
          From concept to launch, we use our technical expertise, creativity,
          and innovation to develop tailored solutions that help our clients
          achieve success online.
        </motion.p>
        <motion.p
          initial={{ x: -300, opacity: 0 }}
          animate={{
            x: 0,
            opacity: 1,
            transition: { type: "spring", duration: 0.8, delay: 2.2 },
          }}
        >
          Our portfolio includes a variety of projects, including e-commerce
          sites, corporate websites, landing pages, and mobile applications. We
          have worked with a diverse range of clients in various industries,
          delivering projects that have helped them establish a strong online
          presence and achieve their business objectives.
        </motion.p>
        <motion.p
          initial={{ x: -300, opacity: 0 }}
          animate={{
            x: 0,
            opacity: 1,
            transition: { type: "spring", duration: 0.8, delay: 2.3 },
          }}
        >
          At Suprads, we believe that our portfolio speaks for itself and
          demonstrates our ability to deliver exceptional web development
          services. If you're looking for a web development partner that can
          help you achieve your online goals, look no further than Suprads.
          Contact us today to learn more about our services and how we can help
          your business succeed online.
        </motion.p>
      </HeroSec>
      <CasesPortfolio variants={variants} />
      <WpTg />
    </>
  );
};

export default Portfolio;
