import React from "react";
import { useRef, useState } from "react";
import { FlexBox } from "./FlexBox";
import { motion } from "framer-motion";
import "../styles/Form.css";
import axios from "axios";
import { useTranslation } from "react-i18next";

const Form = ({ variants, color, devLanding }) => {
  const [t] = useTranslation("global");
  const [data, setData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const buttonVariants = {
    loading: {
      opacity: 0.6,
      cursor: "not-allowed",
    },
    idle: {
      opacity: 1,
      cursor: "pointer",
    },
    zoomInitial: {
      scale: 0,
    },
    zoomIn: {
      scale: 1,
    },
  };

  const urlHook = "https://hook.us1.make.com/m9lce32ija3ydc9t10ysd2bdc1n8ame6";
  const form = useRef(null);
  const [formLoading, setFormLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [error, setError] = useState("");
  const handleInputChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  const onSubmit = async (formData) => {
    formData.preventDefault();
    setError("");
    setFormLoading(true);
    try {
      await axios
        .post(urlHook, data, {
          Accept: "application/json",
          "Content-Type": "application/json",
        })
        .then(function (response) {
          console.log(response);
          form.current.reset();
          setFormLoading(false);
          setIsSubmit(true);
        })
        .catch(function (error) {
          console.log(error);
          console.log("Hubo un problema");
          setError("Hubo un problema al enviar los datos");
          setFormLoading(false);
        });
      form.current.reset();
    } catch (error) {
      console.log(error);
      console.log("Hubo un problema");
      setError("Hubo un problema al enviar los datos");
      setFormLoading(false);
    }
  };

  return (
    <FlexBox
      id="contact"
      className={devLanding ? "section relative blacksec" : "section relative"}
      allign="center"
      direction="col"
    >
      <FlexBox
        className="container-lg"
        allign="left"
        justify="center"
        direction="column"
        wrap="wrap"
      >
        <motion.div
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.98 }}
          id="contacto"
        >
          <FlexBox
            className="form-container relative shadow"
            allign="center"
            direction="column"
            wrap="nowrap"
          >
            <motion.h2
              className="textCenter"
              viewport={{ once: true }}
              variants={variants}
              initial="initial"
              whileInView="fadeinup"
            >
              {devLanding ? "Request a quote today!" : t("form.title")}
            </motion.h2>
            <motion.p
              className="textCenter"
              viewport={{ once: true }}
              variants={variants}
              initial="initial"
              whileInView="fadeinup"
            >
              {devLanding
                ? "We are happy to give you a quick start. Please fill in your details below to get started."
                : t("form.subtitle")}
            </motion.p>
            <motion.form
              className="form"
              ref={form}
              onSubmit={(e) => onSubmit(e)}
              viewport={{ once: true }}
              variants={variants}
              initial="initial"
              whileInView="fadeinup"
            >
              <label>
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15 15C18.4518 15 21.25 12.2018 21.25 8.75C21.25 5.29822 18.4518 2.5 15 2.5C11.5482 2.5 8.75 5.29822 8.75 8.75C8.75 12.2018 11.5482 15 15 15Z"
                    stroke="#292D32"
                    strokeWidth="1.875"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    opacity="0.4"
                    d="M25.7375 27.5C25.7375 22.6625 20.925 18.75 15 18.75C9.07501 18.75 4.26251 22.6625 4.26251 27.5"
                    stroke="#292D32"
                    strokeWidth="1.875"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <input
                  type="text"
                  name="name"
                  placeholder={t("form.name")}
                  onChange={handleInputChange}
                  required
                />
              </label>
              <label>
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21.25 25.625H8.75C5 25.625 2.5 23.75 2.5 19.375V10.625C2.5 6.25 5 4.375 8.75 4.375H21.25C25 4.375 27.5 6.25 27.5 10.625V19.375C27.5 23.75 25 25.625 21.25 25.625Z"
                    stroke="#292D32"
                    strokeWidth="1.875"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    opacity="0.4"
                    d="M21.25 11.25L17.3375 14.375C16.05 15.4 13.9375 15.4 12.65 14.375L8.75 11.25"
                    stroke="#292D32"
                    strokeWidth="1.875"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  pattern="[^@]+@[^@]+\.[a-zA-Z]{2,6}"
                  onChange={handleInputChange}
                  required
                />
              </label>
              <label>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21.97 18.33C21.97 18.69 21.89 19.06 21.72 19.42C21.55 19.78 21.33 20.12 21.04 20.44C20.55 20.98 20.01 21.37 19.4 21.62C18.8 21.87 18.15 22 17.45 22C16.43 22 15.34 21.76 14.19 21.27C13.04 20.78 11.89 20.12 10.75 19.29C9.6 18.45 8.51 17.52 7.47 16.49C6.44 15.45 5.51 14.36 4.68 13.22C3.86 12.08 3.2 10.94 2.72 9.81C2.24 8.67 2 7.58 2 6.54C2 5.86 2.12 5.21 2.36 4.61C2.6 4 2.98 3.44 3.51 2.94C4.15 2.31 4.85 2 5.59 2C5.87 2 6.15 2.06 6.4 2.18C6.66 2.3 6.89 2.48 7.07 2.74L9.39 6.01C9.57 6.26 9.7 6.49 9.79 6.71C9.88 6.92 9.93 7.13 9.93 7.32C9.93 7.56 9.86 7.8 9.72 8.03C9.59 8.26 9.4 8.5 9.16 8.74L8.4 9.53C8.29 9.64 8.24 9.77 8.24 9.93C8.24 10.01 8.25 10.08 8.27 10.16C8.3 10.24 8.33 10.3 8.35 10.36C8.53 10.69 8.84 11.12 9.28 11.64C9.73 12.16 10.21 12.69 10.73 13.22C11.27 13.75 11.79 14.24 12.32 14.69C12.84 15.13 13.27 15.43 13.61 15.61C13.66 15.63 13.72 15.66 13.79 15.69C13.87 15.72 13.95 15.73 14.04 15.73C14.21 15.73 14.34 15.67 14.45 15.56L15.21 14.81C15.46 14.56 15.7 14.37 15.93 14.25C16.16 14.11 16.39 14.04 16.64 14.04C16.83 14.04 17.03 14.08 17.25 14.17C17.47 14.26 17.7 14.39 17.95 14.56L21.26 16.91C21.52 17.09 21.7 17.3 21.81 17.55C21.91 17.8 21.97 18.05 21.97 18.33Z"
                    stroke="#292D32"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                  />
                  <path
                    opacity="0.4"
                    d="M18.5 9C18.5 8.4 18.03 7.48 17.33 6.73C16.69 6.04 15.84 5.5 15 5.5"
                    stroke="#292D32"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    opacity="0.4"
                    d="M22 9C22 5.13 18.87 2 15 2"
                    stroke="#292D32"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>

                <input
                  type="tel"
                  name="phone"
                  placeholder={t("form.phone")}
                  onChange={handleInputChange}
                  required
                />
              </label>
              <label>
                <svg
                  width="29"
                  height="29"
                  viewBox="0 0 29 29"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.2709 22.9584H9.66669C4.83335 22.9584 2.41669 21.7501 2.41669 15.7084V9.66675C2.41669 4.83341 4.83335 2.41675 9.66669 2.41675H19.3334C24.1667 2.41675 26.5834 4.83341 26.5834 9.66675V15.7084C26.5834 20.5417 24.1667 22.9584 19.3334 22.9584H18.7292C18.3546 22.9584 17.9921 23.1397 17.7625 23.4417L15.95 25.8584C15.1525 26.9217 13.8475 26.9217 13.05 25.8584L11.2375 23.4417C11.0442 23.1759 10.5971 22.9584 10.2709 22.9584Z"
                    stroke="#292D32"
                    strokeWidth="1.8125"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    opacity="0.4"
                    d="M19.3291 13.2917H19.3399"
                    stroke="#292D32"
                    strokeWidth="2.41667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    opacity="0.4"
                    d="M14.4945 13.2917H14.5054"
                    stroke="#292D32"
                    strokeWidth="2.41667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    opacity="0.4"
                    d="M9.66003 13.2917H9.67088"
                    stroke="#292D32"
                    strokeWidth="2.41667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <input
                  type="text"
                  name="message"
                  placeholder={t("form.project")}
                  onChange={handleInputChange}
                  required
                />
              </label>
              <span className="error">{error}</span>

              {isSubmit ? (
                <motion.span
                  variants={buttonVariants}
                  initial="zoomInitial"
                  animate="zoomIn"
                  className="isSubmit"
                >
                  {devLanding
                    ? "Thank you! We received your data"
                    : "Thank you! We received your data"}

                  <motion.svg
                    variants={buttonVariants}
                    initial="zoomInitial"
                    transition={{ delay: 0.5 }}
                    animate={{
                      scale: 1,
                    }}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.4"
                      d="M7.48 18.35L10.58 20.75C10.98 21.15 11.88 21.35 12.48 21.35H16.28C17.48 21.35 18.78 20.45 19.08 19.25L21.48 11.95C21.98 10.55 21.08 9.34997 19.58 9.34997H15.58C14.98 9.34997 14.48 8.84997 14.58 8.14997L15.08 4.94997C15.28 4.04997 14.68 3.04997 13.78 2.74997C12.98 2.44997 11.98 2.84997 11.58 3.44997L7.48 9.54997"
                      stroke="#292D32"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                    />
                    <path
                      d="M2.38 18.35V8.55002C2.38 7.15002 2.98 6.65002 4.38 6.65002H5.38C6.78 6.65002 7.38 7.15002 7.38 8.55002V18.35C7.38 19.75 6.78 20.25 5.38 20.25H4.38C2.98 20.25 2.38 19.75 2.38 18.35Z"
                      stroke="#292D32"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </motion.svg>
                </motion.span>
              ) : (
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  variants={buttonVariants}
                  animate={formLoading ? "loading" : "idle"}
                  style={color}
                  type="submit"
                  className="button shadow disable"
                >
                  {devLanding
                    ? formLoading
                      ? "Sending data..."
                      : "Send form"
                    : formLoading
                    ? t("form.loading")
                    : t("form.send")}
                </motion.button>
              )}
            </motion.form>
          </FlexBox>
        </motion.div>
      </FlexBox>
    </FlexBox>
  );
};

export default Form;
